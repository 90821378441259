import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Form,
  Input,
  Row,
  Table,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Button,
  FormGroup,
  Label,
} from "reactstrap";
import { getMethodAuth, postMethod } from "../api";
import Breadcrumbs from "../layout/breadcrumb";
import Loader from "../layout/loader/Loader";
import Pagination from "../utils/Pagination";
import Layout from "./Layout";
import DateRangeFormat from "../utils/DateRangeFormat";
import TableScroll from "../utils/TableScroll";
import SweetAlert from "sweetalert2";
import { POINT_TRANSFER_API, POINT_TRANSFER_HISTORY_API } from "../api/routes";
import { decodeData } from "../utils/Helper";
import { MinusCircle, PlusCircle } from "react-feather";
import moment from "moment";

const PointTransHistory = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [totalPage, setTotalPage] = useState(0);
  const [from, setFrom] = useState(1);
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  const [searchId, setSearchId] = useState("");
  const [startDate, setStartDate] = useState(moment().format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));
  const [refr, setRefr] = useState(true);
  const [userId, setUserId] = useState("");
  const [points, setPoints] = useState("");
  const [remark, setRemark] = useState("");
  const [pointModal, setPointModal] = useState(null);
  const [type, setType] = useState("");

  useEffect(() => {
    setLoading(true);
    const token = decodeData(localStorage.getItem("y_g_n_d_a"));
    (async () => {
      if (token) {
        const res = await getMethodAuth(
          `${POINT_TRANSFER_HISTORY_API}?start_date=${startDate}&end_date=${endDate}&user_id=${searchId}&page=${page}&status=${type}`,
          token.token
        );
        if (res) {
          setLoading(false);
          if (res.message === "Unauthenticated.") {
            localStorage.removeItem("y_g_n_d_a");
            navigate("/admin/login");
          }
          if (res.status === "success") {
            if (res.data) {
              setData([]);
              setData(res.data);
            }
            if (res.meta) {
              if (res.meta.from) {
                setFrom(res.meta.from);
              }
              if (res.meta.last_page) {
                setTotalPage(res.meta.last_page);
              }
            }
          } else {
            setData([]);
          }
        } else {
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    })();
  }, [refr, page, type]);

  const searchSubmitHandler = () => {
    setRefr((prev) => !prev);
  };

  const searchIdHandler = (e) => {
    setSearchId(e.target.value);
  };

  const chunk = (num) => {
    let str = num.toString().split(".");
    if (str[0].length >= 4) {
      str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, "$1,");
    }
    if (str[1] && str[1].length >= 5) {
      str[1] = str[1].replace(/(\d{3})/g, "$1 ");
    }
    return str.join(".");
  };

  const transferPoint = async () => {
    setLoading(true);
    let temp = pointModal;
    setPointModal(null);
    const token = decodeData(localStorage.getItem("y_g_n_d_a"));
    const data = {
      points: points,
      status: temp?.status,
      user_id: userId,
      remark: remark,
    };
    if (token) {
      const res = await postMethod(POINT_TRANSFER_API, data, token.token);
      if (res) {
        if (res.status === "success") {
          SweetAlert.fire({
            icon: "success",
            width: 300,
            title: "Success",
            text: res?.message,
          });
          setLoading(false);
          setRefr(!refr);
        } else {
          SweetAlert.fire({
            icon: "error",
            width: 300,
            title: "Error",
            text:
              res?.message?.points ||
              res?.message?.status ||
              res?.message?.user_id ||
              res?.message?.remark ||
              res?.message?.amount ||
              res?.message,
          });
          setPointModal(temp);
          setLoading(false);
        }
        if (res.message === "Unauthenticated.") {
          localStorage.removeItem("y_g_n_d_a");
          navigate("/admin/login");
        }
      } else {
        SweetAlert.fire({
          icon: "error",
          width: 300,
          title: "Error",
          text: res.message,
        });
        setPointModal(temp);
        setLoading(false);
      }
    } else {
      setPointModal(temp);
      setLoading(false);
    }
  };
  return (
    <>
      <Layout>
        <Breadcrumbs title="Point Transfer" />
        <Container fluid={true}>
          <Row>
            <Col sm="12">
              <Card>
                <CardHeader className="py-4 d-flex justify-content-end">
                  <Col sm="12">
                    <Form>
                      <Row className="justify-content-end">
                        <Col md="4 px-1 mb-2">
                          <div className="d-flex justify-content-center align-items-center gap-3">
                            <button
                              className="btn btn-success w-100 text-white d-flex justify-content-center align-items-center"
                              isabled={loading}
                              type="button"
                              onClick={() =>
                                setPointModal({
                                  status: "increase",
                                  type: "deposit",
                                })
                              }
                            >
                               <PlusCircle size={20} className="me-2" /> 
                              {loading ? "Loading..." : "Point"}
                            </button>
                            <button
                              className="btn btn-danger w-100 text-white d-flex justify-content-center align-items-center"
                              isabled={loading}
                              type="button"
                              onClick={() =>
                                setPointModal({
                                  status: "decrease",
                                  type: "withdraw",
                                })
                              }
                            >
                                 <MinusCircle size={20} className="me-2" /> 
                              {loading ? "Loading..." : "Point"}
                            </button>
                          </div>
                        </Col>
                        <Col md="4 px-1 mb-2">
                          <Input
                            className="form-control"
                            onChange={searchIdHandler}
                            type="number"
                            placeholder="Search ID"
                          />
                        </Col>
                        <Col md="4 px-1 mb-2">
                          <DateRangeFormat
                            setStartDate={setStartDate}
                            setEndDate={setEndDate}
                          />
                        </Col>
                        <Col md="4 px-1 mb-2">
                            <Input type="select" name="select" className="form-control" value={type} onChange={e => setType(e.target.value)}>
                                <option value="">Select Status</option>
                                <option value="">All</option>
                                <option value="increase">In</option>
                                <option value="decrease">Out</option>
                            </Input>
                        </Col>
                        <Col md="4 px-1 mb-2">
                          <button
                            className="w-100 btn text-white btn-primary"
                            disabled={loading}
                            type="button"
                            onClick={searchSubmitHandler}
                          >
                            {loading ? "Loading..." : "Submit"}
                          </button>
                        </Col>
                      </Row>
                    </Form>
                  </Col>
                </CardHeader>
                <CardBody className="position-relative">
                  <TableScroll>
                    <Table className="table-border-vertical">
                      <thead>
                        <tr
                          className="bg-dark"
                          style={{ verticalAlign: "middle" }}
                        >
                          <th className="text-center text-white" scope="col">
                            <b>No.</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>User ID</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>User Name</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>Amount</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>Initial Point</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>Transfer Point</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>Final Point</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>Status</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>Remark</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>Operator</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>Date</b>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.length > 0 &&
                          data.map((el, i) => (
                            <tr key={i}>
                              <th className="text-center" scope="row">{`${
                                i + from
                              }.`}</th>
                              <td className="text-center">{el.user_id}</td>
                              <td className="text-center">{el.user_name}</td>
                              <td style={{ textAlign: "right" }}>
                                {el.amount ? chunk(el.amount) : 0}
                              </td>
                              <td style={{ textAlign: "right" }}>
                                {el.initial_point
                                  ? chunk(el.initial_point)
                                  : 0}
                              </td>
                              <td style={{ textAlign: "right" }}>
                                {el.transfer_point
                                  ? chunk(el.transfer_point)
                                  : 0}
                              </td>
                              <td style={{ textAlign: "right" }}>
                                {el.final_point ? chunk(el.final_point) : 0}
                              </td>
                              <td
                                className={`text-center ${
                                  el.type === "increase"
                                    ? "text-success"
                                    : "text-danger"
                                }`}
                              >
                                {el.type === "increase" ? "In" : "Out"}
                              </td>
                              <td className="text-center">
                                {el.remark ?? "- -"}
                              </td>
                              <td className="text-center">
                                {el.receiver_name ?? "- -"}
                              </td>
                              <td className="text-center">{el.created_at}</td>
                            </tr>
                          ))}
                      </tbody>
                      <tfoot>
                        <tr className="bg-dark">
                          <td colSpan={3} className="text-center text-white">
                            Total
                          </td>
                          <td className="text-end text-white">
                            {chunk(
                              data.length > 0
                                ? data
                                    .reduce(
                                      (accumulator, currentValue) =>
                                        accumulator +
                                        Number(currentValue.amount),
                                      0
                                    )
                                    .toFixed(2)
                                : 0
                            )}
                          </td>
                          <td className="text-end text-white">
                            {chunk(
                              data.length > 0
                                ? data
                                    .reduce(
                                      (accumulator, currentValue) =>
                                        accumulator +
                                        Number(currentValue.initial_point),
                                      0
                                    )
                                    .toFixed(2)
                                : 0
                            )}
                          </td>
                          <td className="text-end text-white">
                            {chunk(
                              data.length > 0
                                ? data
                                    .reduce(
                                      (accumulator, currentValue) =>
                                        currentValue.type === "increase"
                                          ? accumulator +
                                            Number(currentValue.transfer_point)
                                          : accumulator -
                                            Number(
                                              currentValue.transfer_point
                                            ),
                                      0
                                    )
                                    .toFixed(2)
                                : 0
                            )}
                          </td>
                          <td className="text-end text-white">
                            {chunk(
                              data.length > 0
                                ? data
                                    .reduce(
                                      (accumulator, currentValue) =>
                                        accumulator +
                                        Number(currentValue.final_point),
                                      0
                                    )
                                    .toFixed(2)
                                : 0
                            )}
                          </td>
                          <td colSpan={4} className="text-end text-white"></td>
                        </tr>
                      </tfoot>
                    </Table>
                  </TableScroll>
                  {loading && <Loader />}
                </CardBody>
                <CardFooter className="d-flex justify-content-end">
                  <Pagination
                    setPage={setPage}
                    totalPage={totalPage}
                    setLoading={setLoading}
                  />
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </Container>
      </Layout>
      <Modal isOpen={pointModal} toggle={() => setPointModal(null)} centered>
        <Form>
          <ModalHeader>
            Point <span className="text-capitalize">{pointModal?.type}</span>
          </ModalHeader>
          <ModalBody>
            <FormGroup>
              <Label htmlFor="">User ID</Label>
              <Input
                type="text"
                value={userId}
                placeholder="Enter user id"
                onChange={(e) => {
                  setUserId(e.target.value);
                }}
                className="form-control bg-white"
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="">Point</Label>
              <Input
                type="text"
                value={points}
                placeholder="Enter points"
                onChange={(e) => {
                  setPoints(e.target.value);
                }}
                className="form-control bg-white"
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="">Remark</Label>
              <Input
                type="text"
                value={remark}
                placeholder="Enter remark"
                onChange={(e) => {
                  setRemark(e.target.value);
                }}
                className="form-control bg-white"
              />
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => setPointModal(null)}>
              Close
            </Button>
            <Button disabled={loading} color="primary" onClick={transferPoint}>
              Save
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
};

export default PointTransHistory;
