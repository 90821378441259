import React, { useEffect, useState } from "react";
import Breadcrumb from "../layout/breadcrumb";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  InputGroup,
  Button,
  Form,
  CardFooter,
  Label,
  InputGroupText,
  Input,
} from "reactstrap";
import Layout from "./Layout";
import Loader from "../layout/loader/Loader";
import { getMethod, postMethod } from "../api";
import SweetAlert from "sweetalert2";
import { AGENT_API, GET_OTP_API, REGISTER_API, STREAMER_API } from "../api/routes";
import { checkError, decodeData, encodeData } from "../utils/Helper";

const UserCreate = () => {
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [conPassword, setConPassword] = useState("");
  const [bankAccountNumber, setBankAccountNumber] = useState("");
  const [bankAccountName, setBankAccountName] = useState("");
  const [bankName, setBankName] = useState("");
  const [verify, setVerify] = useState("");
  const [role, setRole] = useState('');
  const [streamerId, setStreamerId] = useState('');
  const [nameValidate, setNameValidate] = useState("");
  const [phoneValidate, setPhoneValidate] = useState("");
  const [passwordValidate, setPasswordValidate] = useState("");
  const [conPasswordValidate, setConPasswordValidate] = useState("");
  const [verifyValidate, setVerifyValidate] = useState("");
  const [bankAccoutNumberValidate, setBankAccoutNumberValidate] = useState("");
  const [bankAccoutNameValidate, setBankAccoutNameValidate] = useState("");
  const [bankNameValidate, setBankNameValidate] = useState("");
  const [roleValidate, setRoleValidate] = useState('');
  const [streamers, setStreamers] = useState([]);
  const [agents, setAgents] = useState([]);
  const [agentId, setAgentId] = useState('');
  const [authRole, setAuthRole] = useState('');
  const [togglePassword, setTogglePassword] = useState(false);
  const [toggleConPassword, setToggleConPassword] = useState(false);

  useEffect(() => {
    (async () => {
      const token = decodeData(localStorage.getItem("y_g_n_d_a"));
      if (!token) return;
      setAuthRole(token.user_role)
      if (token.user_role === 'admin') {
        setLoading(true);
        const res = await getMethod(STREAMER_API, token.token);
        const resAgent = await getMethod(AGENT_API, token.token);
        res && setLoading(false);
        res?.status === "success" && setStreamers(res?.data);
        resAgent?.status === "success" && setAgents(resAgent?.data);
      }
    })();
  }, []);

  const registerSubmitHandler = async (e) => {
    e.preventDefault();
    if (!name) {
      setNameValidate("Name is required.");
      return;
    }
    if (!phone) {
      setPhoneValidate("Phone number is required.");
      return;
    }
    if (!verify) {
      setVerifyValidate('Verification code is required.')
      return;
    }
    // if (role === '' || role === 'user') {
    //   if (!bankName) {
    //     setBankNameValidate('Bank name is required.');
    //     return;
    //   }
    //   if (!bankAccountName) {
    //     setBankAccoutNameValidate('Bank account name is required.');
    //     return;
    //   }
    //   if (!bankAccountNumber) {
    //     setBankAccoutNumberValidate('Bank account number is required.');
    //     return;
    //   }
    // }
    if (!password) {
      setPasswordValidate('Password is required.');
      return;
    }
    if (password.length !== 8) {
      setPasswordValidate('Password must be minimum 8 characters.');
      return;
    }
    if (!conPassword) {
      setConPasswordValidate('Confirm password is required.');
      return;
    }
    if (password !== conPassword) {
      setConPasswordValidate('Password does not match.')
      return;
    }
    setLoading(true);
    const data = {
      name,
      phone,
      password,
      password_confirmation: conPassword,
      otp: `${verify}`,
      role,
      streamer_id: streamerId,
      agent_id: agentId,
      // bank_name: bankName,
      // bank_acc_name: bankAccountName,
      // bank_acc_no: bankAccountNumber,
    };
    const res = await postMethod(REGISTER_API, { data_info: encodeData(data)});
    res && setLoading();
    if (!res?.result) {
      checkError(res);
      return;
    }
    const decodeRes = JSON.parse(decodeData(res?.result));
    if (decodeRes?.status === 'success') {
        SweetAlert.fire({
            icon: "success",
            width: 300,
            title: "Success!",
            text: "Register Successful!",
        });
        setName("");
        setPhone("");
        setVerify("");
        setPassword("");
        setConPassword("");
        setBankAccountNumber("");
        setBankAccountName("");
        setBankName("");
        setPasswordValidate("");
        setPhoneValidate("");
        return;
    } else {
        SweetAlert.fire({
            icon: "error",
            width: 300,
            title: "Sorry!",
            text: (decodeRes?.field ?? '') + ' ' + (decodeRes?.message ?? 'Something wrong, Try again!'),
        });
    }
  };

  const getOtp = async (e) => {
    if (phone.length > 5 && phone.length < 12) {
        setPhoneValidate('');
    } else {
        setPhoneValidate("Phone number is minium 6 and maxinum 11");
        return;
    }
    setLoading(true);
    const res = await postMethod(`${GET_OTP_API}`, {data_info: encodeData({
      phone,
      })});
    res && setLoading(false);
    if (!res?.result) {
      checkError(res);
      return;
    }
    const decodeRes = JSON.parse(decodeData(res?.result));
    if (decodeRes?.status === 'success') {
        setVerify(decodeRes?.verification_code);
        setVerifyValidate("");
        return;
    } else {
        SweetAlert.fire({
            icon: "error",
            width: 300,
            title: "Sorry!",
            text: (decodeRes?.field ?? '') + ' ' + decodeRes?.message ?? 'Something wrong, Try again!',
        });
    }
  };
  return (
    <>
      <Layout>
        <Breadcrumb title="Create User" />
        <Container fluid={true}>
          <Row>
            <Card>
              <Col sm="12" xl="6">
                <Row>
                  <Col sm="12">
                    <Form
                      className="theme-form"
                      onSubmit={registerSubmitHandler}
                    >
                      <CardBody>
                        <FormGroup>
                          <Label className="col-form-label pt-0">Name</Label>
                          <Input
                            className="form-control"
                            value={name}
                            type="text"
                            placeholder="Enter Name"
                            onChange={(e) => {
                              setName(e.target.value);
                              setNameValidate("");
                            }}
                          />
                          {nameValidate && (
                            <div className="invalid-feedback d-block">
                              {nameValidate}
                            </div>
                          )}
                        </FormGroup>
                        <FormGroup>
                          <Label>Phone</Label>
                          <InputGroup>
                            <Input
                              className="form-control"
                              value={phone}
                              type="number"
                              placeholder="Enter Phone"
                              aria-label=""
                              onChange={(e) => {
                                setPhone(e.target.value);
                                setPhoneValidate("");
                              }}
                            />
                            <InputGroupText className="p-0 border-0">
                              <Button
                                type="button"
                                style={{
                                  padding: "0.5rem 0.75rem",
                                }}
                                color="primary"
                                className="text-white"
                                onClick={getOtp}
                              >
                                GET OTP
                              </Button>
                            </InputGroupText>
                          </InputGroup>
                          {phoneValidate && (
                            <div className="invalid-feedback d-block">
                              {phoneValidate}
                            </div>
                          )}
                        </FormGroup>
                        <FormGroup>
                          <Label htmlFor="">OTP Code</Label>
                          <Input
                            className="form-control shadow-none"
                            value={verify}
                            style={{ borderColor: "#ced4da" }}
                            type="number"
                            readOnly
                            placeholder="Code"
                          />
                          {verifyValidate && (
                            <div className="invalid-feedback d-block">
                              {verifyValidate}
                            </div>
                          )}
                        </FormGroup>
                        {/* <FormGroup>
                          <Label htmlFor="">Bank Name</Label>
                          <Input
                            type="select"
                            name="select"
                            value={bankName}
                            className="form-control"
                            onChange={(e) => {
                              setBankName(e.target.value);
                              setBankNameValidate("");
                            }}
                          >
                            <option value="">Select</option>
                            <option value="Thai Bank(Kasikorn)">
                              Thai Bank(Kasikorn)
                            </option>
                            <option value="Wave">Wave</option>
                            <option value="Kpay">Kpay</option>
                            <option value="KBZ Mobile Banking">
                              KBZ Mobile Banking
                            </option>
                            <option value="AYA Mobile Banking">
                              AYA Mobile Banking
                            </option>
                            <option value="CB Mobile Banking">
                              CB Mobile Banking
                            </option>
                          </Input>
                          {bankNameValidate && (
                            <div className="invalid-feedback d-block">
                              {bankNameValidate}
                            </div>
                          )}
                        </FormGroup>
                        <FormGroup>
                          <Label htmlFor="">Bank Account Name</Label>
                          <Input
                            className="form-control"
                            type="text"
                            value={bankAccountName}
                            placeholder="Bank Account Name"
                            onChange={(e) => {
                              setBankAccountName(e.target.value);
                              setBankAccoutNameValidate("");
                            }}
                          />
                          {bankAccoutNameValidate && (
                            <div className="invalid-feedback d-block">
                              {bankAccoutNameValidate}
                            </div>
                          )}
                        </FormGroup>
                        <FormGroup>
                          <Label htmlFor="">Bank Account Number</Label>
                          <Input
                            className="form-control"
                            type="text"
                            value={bankAccountNumber}
                            placeholder="Bank Account Number"
                            onChange={(e) => {
                              setBankAccountNumber(e.target.value);
                              setBankAccoutNumberValidate("");
                            }}
                          />
                          {bankAccoutNumberValidate && (
                            <div className="invalid-feedback d-block">
                              {bankAccoutNumberValidate}
                            </div>
                          )}
                        </FormGroup> */}
                        {
                          authRole === 'admin' &&
                          <FormGroup>
                          <Label htmlFor="">Role</Label>
                          <Input
                            type="select"
                            name="select"
                            value={role}
                            className="form-control"
                            onChange={(e) => {
                              setRole(e.target.value);
                              setRoleValidate("");
                            }}
                          >
                            <option value="">Select</option>
                            <option value="user">User</option>
                            <option value="staff">Staff</option>
                            <option value="streamer">Streamer</option>
                          </Input>
                          {roleValidate && (
                            <div className="invalid-feedback d-block">
                              {roleValidate}
                            </div>
                          )}
                        </FormGroup>
                        }
                        {
                        role === 'user' && 
                        <FormGroup>
                          <Label htmlFor="">Agents</Label>
                          <Input type="select" name="select" className="form-control" value={agentId} onChange={e => setAgentId(e.target.value)}>
                            <option value="">Select Agent</option>
                            {
                              agents?.length > 0 &&
                              agents.map((agent,i) =>
                                  <option value={agent.id} key={i}>{agent.name}</option>
                              )
                            }
                          </Input>
                        </FormGroup>
                        }
                        {
                        role === 'user' && 
                        <FormGroup>
                          <Label htmlFor="">Streamers</Label>
                          <Input type="select" name="select" className="form-control" value={streamerId} onChange={e => setStreamerId(e.target.value)}>
                            <option value="">Select Streamer</option>
                            {
                              streamers?.length > 0 &&
                              streamers.map((streamer,i) =>
                                  <option value={streamer.id} key={i}>{streamer.name}</option>
                              )
                            }
                          </Input>
                        </FormGroup>
                        }
                        <FormGroup className="position-relative">
                          <Label htmlFor="">Password</Label>
                          <Input
                            className="form-control"
                            type={togglePassword ? "text" : "password"}
                            value={password}
                            placeholder="Password"
                            onChange={(e) => {
                              setPassword(e.target.value);
                              setPasswordValidate("");
                            }}
                          />
                          {passwordValidate && (
                            <div className="invalid-feedback d-block">
                              {passwordValidate}
                            </div>
                          )}
                          <div className="show-hide" onClick={() => setTogglePassword(!togglePassword)}><span className={togglePassword ? "" : "show"}></span></div>
                        </FormGroup>
                        <FormGroup className="position-relative">
                          <Label htmlFor="">Confirm Password</Label>
                          <Input
                            className="form-control"
                            type={toggleConPassword ? "text" : "password"}
                            value={conPassword}
                            placeholder="Password"
                            onChange={(e) => {
                              setConPassword(e.target.value);
                              setConPasswordValidate("");
                            }}
                          />
                          {conPasswordValidate && (
                            <div className="invalid-feedback d-block">
                              {conPasswordValidate}
                            </div>
                          )}
                          <div className="show-hide" onClick={() => setToggleConPassword(!toggleConPassword)}><span className={toggleConPassword ? "" : "show"}></span></div>
                        </FormGroup>
                      </CardBody>
                      <CardFooter>
                        <Button
                          color=""
                          className="me-1 text-white btn-primary"
                        >
                          Submit
                        </Button>
                      </CardFooter>
                    </Form>
                  </Col>
                </Row>
              </Col>
              {loading && <Loader />}
            </Card>
          </Row>
        </Container>
      </Layout>
    </>
  );
};

export default UserCreate;
