import React from 'react'
import Sidebar from '../layout/sidebar'
import Header from '../layout/header'
import Footer from '../layout/footer'

const Layout = ({children}) => {
  return (
    <>
       <div className="page-wrapper compact-wrapper" id="pageWrapper">
        <Header />
        <div className="page-body-wrapper">
          <Sidebar />
          <div className="page-body">
            <div>
              {children}
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </>
  )
}

export default Layout