import { createSlice } from '@reduxjs/toolkit';

let initialState = {
    value: "",
};

const getGameBettingWithid = createSlice({
    name: 'count',
    initialState,
    reducers: {
        useridHandler: (state,action) => {
            state.value = action.payload
        }
    }
});

export const {useridHandler} = getGameBettingWithid.actions;
export default getGameBettingWithid.reducer;