import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardFooter,
  Table,
  CardHeader,
  Col,
  Container,
  Form,
  Input,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import { MoreHorizontal } from "react-feather";
import { getMethod } from "../api";
import Breadcrumbs from "../layout/breadcrumb";
import Loader from "../layout/loader/Loader";
import Layout from "./Layout";
import Pagination from "../utils/Pagination";
import DateRangeFormat from "../utils/DateRangeFormat";
import { format } from "date-fns";
import { useSelector } from "react-redux";
import TableScroll from "../utils/TableScroll";
import { decodeData, timeStampFormatter } from "../utils/Helper";
import {
  BETTING_HISTORY_API,
  PROVIDER_API,
  PROVIDER_TYPE_API,
} from "../api/routes";
const GameBetting = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [startDate, setStartDate] = useState(format(new Date(), "yyyy-MM-dd"));
  const [endDate, setEndDate] = useState(format(new Date(), "yyyy-MM-dd"));
  const [searchName, setSearchName] = useState("");
  const [totalPage, setTotalPage] = useState(0);
  const [from, setFrom] = useState(1);
  const [page, setPage] = useState(1);
  const [providerCode, setProviderCode] = useState("");
  const [providerType, setProviderType] = useState("");
  const [providerTypes, setProviderTypes] = useState([]);
  const [providers, setProviders] = useState([]);
  const [showDetail, setShowDetail] = useState(false);
  const [detailData, setDetailData] = useState("");
  const { value } = useSelector((state) => state.searchBetting);
  const [searchId, setSearchId] = useState(value);
  const [refr, setRefr] = useState(true);

  useEffect(() => {
    const token = decodeData(localStorage.getItem("y_g_n_d_a"));
    if (!token) return;
    (async () => {
      setLoading(true);
      const res = await getMethod(
        `${BETTING_HISTORY_API}?${providerCode && `site=${providerCode}&`}${
          providerType && `product=${providerType}&`
        }${searchName && `name=${searchName}&`}${
          searchId && `user_id=${searchId}&`
        }start_date=${startDate}&end_date=${endDate}&page=${page}&limit=100`,
        token.token
      );

      res && setLoading(false);
      res?.status === "success" && setData(res?.data);

      if (res?.meta?.current_page > 0) {
        if (res?.meta?.per_page && res?.meta?.current_page) {
          setFrom(res?.meta?.per_page * (res?.meta?.current_page - 1) + 1);
        }
      }
      res?.meta?.last_page && setTotalPage(res?.meta?.last_page);
    })();
  }, [refr, page]);

  useEffect(() => {
    (async () => {
      const resProviderCode = await getMethod(PROVIDER_TYPE_API + '?dashboard=true');
      resProviderCode.status === "success" &&
        setProviderTypes(resProviderCode.data);

      const resProviderType = await getMethod(PROVIDER_API + '?dashboard=true');
      resProviderType?.status === "success" &&
        setProviders(resProviderType?.data);
    })();
  }, []);

  const searchSubmitHandler = () => {
    setRefr((prev) => !prev);
  };

  const searchHandler = (e) => {
    setSearchName(e.target.value);
  };
  const searchIdHandler = (e) => {
    setSearchId(e.target.value);
  };

  const providerCodeHandler = (e) => {
    setProviderCode(e.target.value);
  };
  const providerTypeHandler = (e) => {
    setProviderType(e.target.value);
  };

  const detailModal = () => {
    setShowDetail(!showDetail);
  };

  const chunk = (num) => {
    let str = num.toString().split(".");
    if (str[0].length >= 4) {
      str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, "$1,");
    }
    if (str[1] && str[1].length >= 5) {
      str[1] = str[1].replace(/(\d{3})/g, "$1 ");
    }
    return str.join(".");
  };
  return (
    <>
      <Layout>
        <Breadcrumbs title="Games Betting" />
        <Container fluid={true}>
        {loading && <Loader />}
          <Row>
            <Col sm="12">
              <Card>
                <CardHeader className="py-4 d-flex justify-content-end">
                  <Col sm="12">
                    <Form>
                      <Row className="d-flex justify-content-end">
                        <Col md="4 px-1 mb-2">
                          <Input
                            className="form-control"
                            value={searchId}
                            onChange={searchIdHandler}
                            type="number"
                            placeholder="Search ID"
                          />
                        </Col>
                        <Col md="4 px-1 mb-2">
                          <Input
                            className="form-control"
                            valuse={searchName}
                            onChange={searchHandler}
                            type="text"
                            placeholder="Search Name"
                          />
                        </Col>
                        <Col md="4 px-1 mb-2">
                          <Input
                            type="select"
                            name="select"
                            className="form-control"
                            onChange={providerCodeHandler}
                          >
                            <option value="">Select Provider</option>
                            {providers?.length > 0 &&
                              providers?.map((el, i) => (
                                <option key={el.id} value={el.name}>
                                  {el.name}
                                </option>
                              ))}
                          </Input>
                        </Col>
                        <Col md="4 px-1 mb-2">
                          <Input
                            type="select"
                            name="select"
                            className="form-control"
                            onChange={providerTypeHandler}
                          >
                            <option value="">Select Game Type</option>
                              {providerTypes?.length > 0 &&
                              providerTypes?.map((el, i) => (
                                <option key={el.id} value={el.id}>
                                  {el.name}
                                </option>
                              ))}
                          </Input>
                        </Col>
                        <Col md="4 px-1 mb-2">
                          <DateRangeFormat
                            setStartDate={setStartDate}
                            setEndDate={setEndDate}
                          />
                        </Col>
                        <Col md="4 px-1 mb-2">
                          <button
                            className="w-100 btn text-white"
                            style={{ backgroundColor: "#001753" }}
                            disabled={loading}
                            type="button"
                            onClick={searchSubmitHandler}
                          >
                            {loading ? "Loading..." : "Submit"}
                          </button>
                        </Col>
                      </Row>
                    </Form>
                  </Col>
                </CardHeader>
                <CardBody className="position-relative">
                  <TableScroll>
                    <Table className="table-border-vertical">
                      <thead>
                        <tr
                          className="bg-dark"
                          style={{ verticalAlign: "middle" }}
                        >
                          <th className="text-center text-white" scope="col">
                            <b>{"No."}</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>{"ID"}</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>{"Name"}</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>{"Reg Phone"}</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>{"Provider"}</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>{"Bet"}</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>{"Payout"}</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>{"Profit"}</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>{"Game Name"}</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>{"Ref No"}</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>{"Bet Time"}</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>{"Play Time"}</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>{"Bet Type"}</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>{"Bet Status"}</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>{"Bet Detail"}</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>{"Status"}</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>{"Win/Lose"}</b>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {data?.length > 0 &&
                          data?.map((user, i) => (
                            <tr key={i} style={{ verticalAlign: "middle" }}>
                              <th className="text-center" scope="row">{`${
                                i + from
                              }.`}</th>
                              <td className="text-center">{user.user_id}</td>
                              <td className="text-center">{user.name}</td>
                              <td className="text-center">
                                {user.phone ? user.phone : "--"}
                              </td>
                              <td className="text-center">{user.site}</td>
                              <td className="text-center">
                                {user.bet ? user?.bet?.toLocaleString('en-us') : 0}
                              </td>
                              <td className="text-center">
                                {user.payout ? user?.payout?.toLocaleString('en-us') : 0}
                              </td>
                              <td className="text-center">
                                {user.win_lose_amount
                                  ? chunk(user.win_lose_amount)
                                  : 0}
                              </td>
                              <td className="text-center">
                                {user.game_name ? user.game_name : "--"}
                              </td>
                              <td className="text-center">{user.ref_no}</td>
                              <td className="text-center">{timeStampFormatter(user.start_time)}</td>
                              <td className="text-center">{timeStampFormatter(user.match_time)}</td>
                              <td className="text-center">{user.bet_type}</td>
                              <td className="text-center">
                                <span
                                  className={`badge badge-${
                                    user.bet_status === "Accept"
                                      ? "info"
                                      : user.bet_status === "END"
                                      ? "success"
                                      : "danger"
                                  }`}
                                >
                                  {user.bet_status}
                                </span>
                              </td>

                              <td className="text-center">
                                <span
                                  onClick={() => {
                                    detailModal();
                                    setDetailData(user.bet_detail);
                                  }}
                                >
                                  <MoreHorizontal />
                                </span>
                              </td>
                              <td className="text-center">
                                <span
                                  className={`badge badge-${
                                    user.status === "Waiting" ? "info" : "dark"
                                  }`}
                                >
                                  {user.status}
                                </span>
                              </td>
                              <td className="text-center">
                                <span
                                  className={`badge badge-${
                                    user.win_lose === "LOSE"
                                      ? "danger"
                                      : user.win_lose === "WIN"
                                      ? "success"
                                      : "info"
                                  }`}
                                >
                                  {user.win_lose}
                                </span>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                      <tfoot>
                        <tr className="bg-dark">
                          <td colSpan={5} className="text-center text-white">
                            Total
                          </td>
                          <td className="text-end text-white">
                            {chunk(
                              data.length > 0
                                ? data
                                    .reduce(
                                      (accumulator, currentValue) =>
                                        accumulator + Number(currentValue.bet),
                                      0
                                    )
                                    .toFixed(2)
                                : 0
                            )}
                          </td>
                          <td className="text-end text-white">
                            {chunk(
                              data.length > 0
                                ? data
                                    .reduce(
                                      (accumulator, currentValue) =>
                                        accumulator +
                                        Number(currentValue.payout),
                                      0
                                    )
                                    .toFixed(2)
                                : 0
                            )}
                          </td>
                          <td className="text-end text-white">
                            {chunk(
                              data.length > 0
                                ? data
                                    .reduce(
                                      (accumulator, currentValue) =>
                                        accumulator +
                                        Number(
                                          currentValue.win_lose_amount
                                            ? currentValue.win_lose_amount
                                            : 0
                                        ),
                                      0
                                    )
                                    .toFixed(2)
                                : 0
                            )}
                          </td>
                          <td
                            colSpan={9}
                            className="text-center text-white"
                          ></td>
                        </tr>
                      </tfoot>
                    </Table>
                  </TableScroll>
                </CardBody>
                <CardFooter className="d-flex justify-content-end">
                  <Pagination
                    setPage={setPage}
                    totalPage={totalPage}
                    setLoading={setLoading}
                  />
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </Container>
      </Layout>
      {
        <Modal isOpen={showDetail} toggle={detailModal} centered>
          <ModalHeader toggle={detailModal}>Bet Detail</ModalHeader>
          <ModalBody>{detailData}</ModalBody>
        </Modal>
      }
    </>
  );
};

export default GameBetting;
