import CryptoJS from "crypto-js";
import moment from "moment";
import SweetAlert from "sweetalert2";

export const calculateTotal = (data, name = "amount") => {
  if (data?.length > 0) {
    return data
      .reduce((total, item) => total + +item[name], 0)
      .toLocaleString("en-us")
  } else {
    return 0
  }
};

export const calculateProfit = (data, first = "amount", second = "amount") => {
  if (data?.length > 0) {
    return data
      .reduce((total, item) => total + (item[first] - item[second]), 0)
      .toLocaleString("en-us")
  } else {
    return 0
  }
};

export const calculateNetProfit = (
  data,
  target_key = "status",
  target_value = "increase",
  name = "amount"
) => {
  if (data?.length > 0) {
    return data
      .reduce((total, item) => {
        return item[target_key] === target_value
          ? total + +item[name]
          : total - +item[name]
      }, 0)
      .toLocaleString("en-us")
  } else {
    return 0
  }
};

export const numericeValueFilter = (input) => {
  const numericValue = input.replace(/\D/g, "");
  return numericValue;
};

export const encodeData = (data) => {
  const deText = CryptoJS.AES.encrypt(
    JSON.stringify(data),
    process.env.REACT_APP_SECRET_KEY
  ).toString();
  return deText;
};

export const decodeData = (data) => {
  try {
    const bytes = CryptoJS.AES.decrypt(data, process.env.REACT_APP_SECRET_KEY);
    const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return decryptedData;
  } catch (err) {
    localStorage.removeItem("y_g_n_d_a");
  }
};

export const relativeTime = (inputDate) => {
  return moment(inputDate).fromNow();
};

export const timeStampFormatter = (input_date) => {
  return moment(input_date).format('MMM D, YYYY h:mm A');
}

export const currentDate = () => moment().format("YYYY-MM-DD HH:mm:ss")

export const copyMe = (value) => {
  var temp = document.createElement("input");
  document.body.appendChild(temp);
  temp.value = value;
  temp.select();
  document.execCommand("copy");
  document.body.removeChild(temp);
}

export const checkError = res => {
  if (res?.status === 'error') {
    const field = res?.field;
    let message = res?.message;
    if (field) {
      message = '[ ' + field + ' ] ' + message;
    }
    SweetAlert.fire({
      icon: "error",
      width: 300,
      title: "Sorry!",
      text: message || 'Something wrong, Try again!',
  });
  }
}