import { createSlice } from '@reduxjs/toolkit';

let initialState = {
    value: true,
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        statusHandler: (state,action) => {
            state.value = action.payload
        }
    }
});

export const {statusHandler} = authSlice.actions;
export default authSlice.reducer;