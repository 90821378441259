import React, { useEffect, useState } from "react";
import Breadcrumb from "../layout/breadcrumb";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Input,
  FormGroup,
  Button,
  Form,
  CardFooter,
  Label,
} from "reactstrap";
import Layout from "./Layout";
import Loader from "../layout/loader/Loader";
import { postMethod } from "../api";
import SweetAlert from "sweetalert2";
import { MANUAL_WALLET_TRANSFER_API, STREAMER_MANUAL_WALLET_TRANSFER_API } from "../api/routes";
import { decodeData, numericeValueFilter } from "../utils/Helper";

const ManualDeposit = () => {
  const [loading, setLoading] = useState(false);
  const [amount, setAmount] = useState("");
  const [user, setUser] = useState("");
  const [remark, setRemark] = useState("");
  const [amountValidate, setAmountValidate] = useState("");
  const [userValidate, setUserValidate] = useState("");
  const [remarkValidate, setRemarkValidate] = useState("");
  const [role, setRole] = useState('User');

  const registerSubmitHandler = async (e) => {
    e.preventDefault();
    const token = decodeData(localStorage.getItem("y_g_n_d_a"));
    if (!token) return;
    if (!user) {
      setUserValidate("User ID is required.");
      return;
    }
    if (!amount) {
      setAmountValidate("Amount is required.");
      return;
    }
    if (+amount <= 0) {
        setAmountValidate('Amount must be greater than zero!');
        return;
    }
    if (!remark) {
        setRemarkValidate("Remark is required.");
        return;
      }
    const data = {
      amount,
      status: "increase",
      user_id: user,
      remark,
    };
    setLoading(true);
    let res;
    switch (role) {
      case 'User':
        res = await postMethod(MANUAL_WALLET_TRANSFER_API, data, token.token);
        break;
      case 'Streamer':
        res = await postMethod(STREAMER_MANUAL_WALLET_TRANSFER_API, data, token.token);
      default:
        break;
    }
    res && setLoading(false);
    if (res?.status === "success") {
      SweetAlert.fire({
        icon: "success",
        width: 300,
        title: "Success!",
        text: res?.message,
      }).then((res) => {
        if (res.isConfirmed) {
          setAmount("");
          setUser("");
          setRemark("");
        }
      });
      return;
    } else {
      SweetAlert.fire({
        icon: "error",
        width: 300,
        title: "Sorry!",
        text: res?.message ?? "Something wrong, Try again!",
      });
      return;
    }
  };

  return (
    <>
      <Layout>
        <Breadcrumb title="Manual Deposit" />
        <Container fluid={true}>
        {loading && <Loader />}
          <Row>
            <Card>
              <Col sm="12" xl="6">
                <Row>
                  <Col sm="12">
                    <Form
                      className="theme-form"
                      onSubmit={registerSubmitHandler}
                    >
                      <CardBody>
                        <FormGroup>
                          <Label className="col-form-label pt-0">Select Role ( Default - User )</Label>
                          <Input type="select" name="select" className="form-control" value={role} onChange={e => setRole(e.target.value)}>
                              <option value="User">User</option>
                              <option value="Streamer">Streamer</option>
                          </Input>
                        </FormGroup>
                        <FormGroup>
                          <Label className="col-form-label pt-0">{role} ID</Label>
                          <Input
                            className="form-control"
                            value={user}
                            type="number"
                            placeholder={`Enter ${role} ID`}
                            onChange={(e) => {
                              setUser(e.target.value);
                              setUserValidate("");
                            }}
                          />
                          {userValidate && (
                            <div className="invalid-feedback d-block">
                              {userValidate}
                            </div>
                          )}
                        </FormGroup>
                        <FormGroup>
                          <Label className="col-form-label pt-0">Amount</Label>
                          <Input
                            className="form-control"
                            value={amount}
                            type="text"
                            placeholder="Enter Amount"
                            onChange={(e) => {
                              setAmount(numericeValueFilter(e.target.value));
                              setAmountValidate("");
                            }}
                          />
                          {amountValidate && (
                            <div className="invalid-feedback d-block">
                              {amountValidate}
                            </div>
                          )}
                        </FormGroup>
                        <FormGroup>
                          <Label className="col-form-label pt-0">Remark</Label>
                          <textarea
                            className="form-control"
                            rows={2}
                            style={{ resize: "none" }}
                            value={remark}
                            type="text"
                            placeholder="Enter Remark"
                            onChange={(e) => {
                              setRemark(e.target.value);
                              setRemarkValidate("");
                            }}
                          />
                          {remarkValidate && (
                            <div className="invalid-feedback d-block">
                              {remarkValidate}
                            </div>
                          )}
                        </FormGroup>
                      </CardBody>
                      <CardFooter>
                        <Button
                          color=""
                          className="me-1 text-white"
                          style={{ backgroundColor: "#001753" }}
                        >
                          Submit
                        </Button>
                      </CardFooter>
                    </Form>
                  </Col>
                </Row>
              </Col>
            </Card>
          </Row>
        </Container>
      </Layout>
    </>
  );
};

export default ManualDeposit;
