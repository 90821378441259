import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Form,
  Input,
  Row,
  Table,
} from "reactstrap";
import { getMethod } from "../api";
import Breadcrumbs from "../layout/breadcrumb";
import Loader from "../layout/loader/Loader";
import Layout from "./Layout";
import Pagination from "../utils/Pagination";
import DateRangeFormat from "../utils/DateRangeFormat";
import TableScroll from "../utils/TableScroll";
import { decodeData } from "../utils/Helper";
import { PROVIDER_API, TRANSACTION_HISTORY_API } from "../api/routes";
import moment from "moment";

const TransactionHistory = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [history, setHistory] = useState([]);
  const [totalPage, setTotalPage] = useState(0);
  const [from, setFrom] = useState(1);
  const [page, setPage] = useState(1);
  const [searchId, setSearchId] = useState("");
  const [providerSearch, setProviderSearch] = useState("");
  const [typeSearch, setTypeSearch] = useState("");
  const [paymentTypeSearch, setPaymentTypeSearch] = useState("");
  const [providerCode, setProviderCode] = useState([]);
  const [startDate, setStartDate] = useState(moment().format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));
  const [refr, setRefr] = useState(true);

  useEffect(() => {
    const token = decodeData(localStorage.getItem("y_g_n_d_a"));
    (async () => {
      if (!token) return;
      setLoading(true);
      const res = await getMethod(
        `${TRANSACTION_HISTORY_API}?status=${typeSearch}&start_date=${startDate}&end_date=${endDate}&user_id=${searchId}&type=${paymentTypeSearch}&provider_name=${providerSearch}&page=${page}`,
        token.token
      );
      res && setLoading(false);
      res?.status === "success" && setData(res.data);
      res?.meta?.from && setFrom(res?.meta?.from);
      res?.meta?.last_page && setTotalPage(res?.meta?.last_page);
    })();
  }, [refr, page]);

  useEffect(() => {
    (async () => {
      let resProviderCode = await getMethod(`${PROVIDER_API}?dashboard=true`);
      resProviderCode?.status === "success" &&
        setProviderCode(resProviderCode?.data);
    })();
  }, []);

  useEffect(() => {
    if (providerCode?.length > 0 && data?.length > 0) {
      const newData = data.map((el) => {
        const provider = providerCode.find(
          (provider) => provider.name === el.provider_name
        );
        if (provider) {
          el.provider_name = provider.name
          return el;
        } else {
          return el;
        }
      });
      setHistory(newData);
    } else {
      setHistory(data);
    }
  }, [data, providerCode])

  const searchSubmitHandler = () => {
    setRefr((prev) => !prev);
  };

  const providerHandler = (e) => {
    setProviderSearch(e.target.value);
  };

  const typeHandler = (e) => {
    setTypeSearch(e.target.value);
  };

  const paymentTypeHandler = (e) => {
    setPaymentTypeSearch(e.target.value);
  };
  const searchIdHandler = (e) => {
    setSearchId(e.target.value);
  };

  return (
    <>
      <Layout>
        <Breadcrumbs title="Transaction History" />
        <Container fluid={true}>
        {loading && <Loader />}
          <Row>
            <Col sm="12">
              <Card>
                <CardHeader className="py-4">
                  <Col sm="12">
                    <Form>
                      <Row className="justify-content-end">
                        <Col md="4 px-1 mb-2">
                          <Input
                            className="form-control"
                            onChange={searchIdHandler}
                            type="number"
                            placeholder="Search ID"
                          />
                        </Col>
                        <Col md="4 px-1 mb-2">
                          <Input
                            type="select"
                            name="select"
                            className="form-control"
                            onChange={providerHandler}
                          >
                            <option value="">Select Provider</option>
                            {providerCode?.length > 0 &&
                              providerCode?.map((el, i) => (
                                <option key={el.id} value={el.name}>
                                  {el.name}
                                </option>
                              ))}
                          </Input>
                        </Col>
                        <Col md="4 px-1 mb-2">
                          <Input
                            type="select"
                            name="select"
                            className="form-control"
                            onChange={typeHandler}
                          >
                            <option value="">Select Type</option>
                            <option value="wallet">Wallet</option>
                            <option value="unit">Unit</option>
                          </Input>
                        </Col>
                        <Col md="4 px-1 mb-2">
                          <Input
                            type="select"
                            name="select"
                            className="form-control"
                            onChange={paymentTypeHandler}
                          >
                            <option value="">Select Status</option>
                            <option value="withdraw">Withdraw</option>
                            <option value="deposit">Deposit</option>
                            <option value="transfer_in">Transfer In</option>
                            <option value="transfer_out">Transfer Out</option>
                          </Input>
                        </Col>
                        <Col md="4 px-1 mb-2">
                          <DateRangeFormat
                            setStartDate={setStartDate}
                            setEndDate={setEndDate}
                          />
                        </Col>
                        <Col md="4 px-1 mb-2">
                          <button
                            className="w-100 btn text-white btn-primary"
                            disabled={loading}
                            type="button"
                            onClick={searchSubmitHandler}
                          >
                            {loading ? "Loading..." : "Submit"}
                          </button>
                        </Col>
                      </Row>
                    </Form>
                  </Col>
                </CardHeader>
                <CardBody className="position-relative">
                  <TableScroll>
                    <Table className="table-border-vertical">
                      <thead>
                        <tr
                          className="bg-dark"
                          style={{ verticalAlign: "middle" }}
                        >
                          <th className="text-center text-white" scope="col">
                            <b>{"No."}</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>{"User ID"}</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>{"Trans ID"}</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>{"Trans No."}</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>{"Type"}</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>{"Bank"}</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>{"Provider"}</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>{"Game Name"}</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>{"Name"}</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>{"Reg Phone"}</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>{"Status"}</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>{"Balance"}</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>{"Action"}</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>{"Date"}</b>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {history?.length > 0 &&
                          history?.map((user, i) => (
                            <tr
                              key={user.id}
                              style={{ verticalAlign: "middle" }}
                            >
                              <th className="text-center" scope="row">{`${
                                i + from
                              }.`}</th>
                              <td className="text-center">{user.user_id}</td>
                              <td className="text-center">
                                {user?.transaction_id ?? "--"}
                              </td>
                              <td className="text-center">
                                {user?.transaction_no ?? "--"}
                              </td>
                              <td className="text-center">{user.status}</td>
                              <td className="text-center">
                                {user?.payment_provider_name ? user?.payment_provider_name : '--'}
                              </td>
                              <td className="text-center">
                                {user?.provider_name ?? "--"}
                              </td>
                              <td className="text-center">
                                {user?.game_code ?? "--"}
                              </td>
                              <td className="text-center">{user?.user_name ? user?.user_name : '--'}</td>
                              <td className="text-center">{user?.user_phone ? user?.user_phone : '--'}</td>
                              <td className="text-center">{user?.type ? user?.type : '--'}</td>
                              <td className="text-center">
                                {user?.balance?.toLocaleString("en-us")}
                              </td>
                              <td className="text-center">
                                <span
                                  className={`badge badge-${
                                    user.action === "Confirm"
                                      ? "success"
                                      : "danger"
                                  }`}
                                >
                                  {user.action}
                                </span>
                              </td>
                              <td className="text-center">{user.created_at}</td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  </TableScroll>
                </CardBody>
                <CardFooter className="d-flex justify-content-end">
                  <Pagination
                    setPage={setPage}
                    totalPage={totalPage}
                    setLoading={setLoading}
                  />
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </Container>
      </Layout>
    </>
  );
};

export default TransactionHistory;
