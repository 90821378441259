import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardFooter,
  Col,
  Container,
  Row,
  Table,
} from "reactstrap";
import Breadcrumbs from "../layout/breadcrumb";
import Layout from "./Layout";
import Loader from "../layout/loader/Loader";
import { getMethod } from "../api";
import Pagination from "../utils/Pagination";
import { Link } from "react-router-dom";
import { decodeData } from "../utils/Helper";
import { PROMOTION_REPORT_API } from "../api/routes";
import { useDispatch, useSelector } from "react-redux";
import { io } from "socket.io-client";
import { likeCountHandler } from "../store/slices/promoLikeCountSlice";

const PromotionUsers = () => {
  const [loading, setLoading] = useState(true);
  const [promotions, setPromotions] = useState([]);
  const [from, setFrom] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [page, setPage] = useState(1);
  let promoCount = 0;
  const { promo } = useSelector(state => state.promo);
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      const token = decodeData(localStorage.getItem("y_g_n_d_a"));
      if (!token) return;
        const res = await getMethod(
          `${PROMOTION_REPORT_API}?dashboard=true`,
          token.token
        );
        res && setLoading(false);
        if (res?.status === "success") {
          setPromotions(res?.data?.data);
          res?.data?.meta?.from && setFrom(res?.data?.meta?.from);
          res?.data?.meta?.last_page && setTotalPage(res?.data?.meta?.last_page);
          res?.data?.data?.map((p) => (promoCount += p?.like_count));
          localStorage.setItem("p_count", promoCount);
        }
    })();
  }, [promo]);

  const callSocket = (e) => {
    const token = decodeData(localStorage.getItem('y_g_n_d_a'))
    const socket = io(`${process.env.REACT_APP_DOMAIN}`);
    socket.emit(`promotion${token.user_id}`, (data) => {
      console.log('promo seen', data)
      localStorage.setItem('p_count', 0);
      dispatch(likeCountHandler({}))
    });
    socket.on(`promotion${token.user_id}`, (data) => {
      console.log('promo seen', data)
      localStorage.setItem('p_count', 0);
      dispatch(likeCountHandler({}))
    });
  }

  return (
    <>
      <Layout>
        <Breadcrumbs title="Promotion Users" />
        <Container fluid={true}>
        {loading && <Loader />}
          <Row>
            <Col sm="12">
              <Card>
                <CardBody className="position-relative">
                  <div>
                    <Table className="table-border-vertical">
                      <thead>
                        <tr className="bg-dark">
                          <th className="text-center text-white" scope="col">
                            <b>{"No."}</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>{"Promotion Type"}</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>{"Promotion Count"}</b>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {promotions?.length > 0 &&
                          promotions?.map((promotion, i) => (
                            <tr key={i} className="align-middle">
                              <th className="text-center" scope="row">{`${
                                i + from
                              }.`}</th>
                              <td className="text-center"> 
                                <Link to={`${promotion.promotion_id}/promo`} onClick={e => callSocket(e)}>
                                  <div className="badge bg-primary">
                                    {promotion.promotion_name}
                                  </div>
                                </Link>
                              </td>
                              <td className="text-center">
                                {promotion.count}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
                {totalPage > 1 && (
                  <CardFooter className="d-flex justify-content-end">
                    <Pagination
                      setPage={setPage}
                      totalPage={totalPage}
                      setLoading={setLoading}
                    />
                  </CardFooter>
                )}
              </Card>
            </Col>
          </Row>
        </Container>
      </Layout>
    </>
  );
};

export default PromotionUsers;
