import { createSlice } from "@reduxjs/toolkit";

const promoLikeCountSlice = createSlice({
  name: "promo",
  initialState: {
    promo: {},
  },
  reducers: {
    likeCountHandler: (state, action) => {
      state.promo = action.payload;
    },
  },
});

export const { likeCountHandler } = promoLikeCountSlice.actions;
export default promoLikeCountSlice.reducer;