import { createSlice } from '@reduxjs/toolkit';

let initialState = {
    refresh: true,
};

const refreshSlice = createSlice({
    name: 'refresh',
    initialState,
    reducers: {
        refreshHandler: (state,action) => {
            state.refresh = action.payload
        }
    }
});

export const {refreshHandler} = refreshSlice.actions;
export default refreshSlice.reducer;