const domain = process.env.REACT_APP_DOMAIN;
const node_domain = process.env.REACT_APP_NODE_DOMAIN;

export const getMethod = async (route, token = '') => {
    try{
        const res = await fetch(route, {
            method: "GET",
            headers: {
                "accept": "application/json",
                "Content-Type": "application/json",
                "Authorization" : `Bearer ${token}`,
            },
        }).then(res => res.json())
        if (
            (res?.message === "Unauthenticated" || res?.message === 'Unauthorized!') ||
            (res?.errors &&
            res?.errors[0]?.message ===
            "E_UNAUTHORIZED_ACCESS: Unauthorized access")
            ) {
            localStorage.removeItem("y_g_n_d_a");
            window.location.reload();
            return;
        }                                           
        return res;
    }
    catch(err){
        console.log(err)
    }
};

export const getMethodAuth = async (route, token = '') => {
    try {
        const res = await fetch(route, {
            method: "GET",
            headers: {
                "accept": "application/json",
                "Content-Type": "application/json",
                "Authorization" : `Bearer ${token}`,
            },
        });
        const resData = res.json()
        return resData;
    }
    catch(err){
        console.log(err);
    };
};

export const postMethod = async (route, data = {}, token = '', method = 'POST') => {
    try {
        const res = await fetch(route, {
            method: method,
            headers: {
                "accept": "application/json",
                "Content-Type": "application/json",
                "Authorization" : `Bearer ${token}`,
            },
            body: JSON.stringify(data)
        }).then(res => res.json())
        if (
            res?.message === "Unauthenticated" ||
            (res?.errors &&
            res?.errors[0]?.message ===
            "E_UNAUTHORIZED_ACCESS: Unauthorized access")
        ) {
            localStorage.removeItem("y_g_n_d_a");
            window.location.reload();
            return;
        }
        return res;
    }
    catch(err){
        console.log(err)
    }
}

export const postMethodFormData = async (route, formData, token = "", method = "POST") => {
    try {
      const res = await fetch(route, {
        method: method,
        headers: {
          accept: "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      }).then((res) => res.json());
      if (
        res?.message === "Unauthenticated" ||
        (res?.errors &&
          res?.errors[0]?.message ===
            "E_UNAUTHORIZED_ACCESS: Unauthorized access")
      ) {
        localStorage.removeItem("y_g_n_d_a");
        window.location.reload();
        return;
      }
      return res;
    } catch (err) {
      return err.message;
    }
  };

export const postMethodWithoutData = async (route,token) => {
    try {
        const res = await fetch(route, {
            method: "POST",
            headers: {
                "accept": "application/json",
                "Content-Type": "application/json",
                "Authorization" : `Bearer ${token}`,
            },
        }).then(res => res.json());
        return res;
    }
    catch(err){
        console.log(err)
    }
};

export const uploadPostMethod = async (route ,data = {}, token = '') => {
    const res = {
        method: "POST",
        url: `${domain}${route}`,
        data,
        headers: {
          Accept: "application/json",
          authorization: `Bearer ${token}`,
        },
    }
    return res;
}

export const deleteMethod = async (route, token) => {
    try {
        const res = await fetch(route, {
            method: "DELETE",
            headers: {
                "accept": "application/json",
                "Content-Type": "application/json",
                "Authorization" : `Bearer ${token}`,
            },
        }).then(res => res.json())
        return res;
    }
    catch(err){
        console.log(err)
    }
}


// new domain 
export const getMethodAuthNewDomain = async (route,token) => {
    try {
        const res = await fetch(`${node_domain}${route}`,{
            method: "GET",
            headers: {
                "accept": "application/json",
                "Content-Type": "application/json",
                "Authorization" : `Bearer ${token}`,
            },
        });
        const resData = res.json()
        return resData;
    }
    catch(err){
        console.log(err);
    };
};