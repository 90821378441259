import { configureStore } from '@reduxjs/toolkit'
import depositWithdrawNotiSlice from './slices/depositWithdrawNotiSlice'
import getGameBettingWithid from './slices/getGameBettingWithid'
import authSlice from './slices/authSlice'
import userInfoSlice from './slices/userInfoSlice'
import refreshSlice from './slices/refreshSlice'
import promoLikeCountSlice from './slices/promoLikeCountSlice'
export const store = configureStore({
  reducer: {
      notiCount: depositWithdrawNotiSlice,
      searchBetting: getGameBettingWithid,
      auth: authSlice,
      userInfoSlice: userInfoSlice,
      refresh: refreshSlice,
      promo: promoLikeCountSlice
  },
})