import React, { useState } from "react";
import { useEffect } from "react";
import SimpleMdeReact from "react-simplemde-editor";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  Row,
} from "reactstrap";
import { getMethod, postMethod } from "../api";
import Breadcrumbs from "../layout/breadcrumb";
import Loader from "../layout/loader/Loader";
import Layout from "./Layout";
import SweetAlert from "sweetalert2";
import { CAPTION_API, CAPTION_UPDATE_API } from "../api/routes";
import { checkError, currentDate, decodeData } from "../utils/Helper";

const Caption = () => {
  const [loading, setLoading] = useState(false);
  const [text, setText] = useState("");
  const [refr, setRefr] = useState(false);
  const [captionid, setCaptionid] = useState("");

  useEffect(() => {
    (async () => {
      setLoading(true);
      const res = await getMethod(
        `${CAPTION_API}`
      );
      res && setLoading(false);
      if (!res?.result) {
        checkError(res);
        return;
      }
      const decodeRes = JSON.parse(decodeData(res?.result));
      decodeRes?.status === "success" && setCaptionid(decodeRes?.data?.id);
      decodeRes?.status === "success" && setText(decodeRes?.data?.caption);
    })();
  }, [refr]);

  const inputHandler = (text) => {
    setText(text);
  };
  const submitCaptionHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!captionid) return;
    const token = decodeData(localStorage.getItem("y_g_n_d_a"));
    if (!token) return;
    const res = await postMethod(
      `${CAPTION_UPDATE_API(captionid)}?updated_at=${currentDate()}`,
      { caption: text },
      token.token,
      "PUT"
    );
    res && setLoading(false);
    if (res?.status === "success") {
      setRefr(!refr);
    } else {
      SweetAlert.fire({
        icon: "error",
        width: 300,
        title: "Sorry!",
        text: res?.message ?? "Something wrong, Try again!",
      });
    }
  };
  return (
    <>
      <Layout>
        <Breadcrumbs title="Banner Caption" />
        <Container fluid={true}>
        {loading && <Loader />}
          <Row>
            <Col sm="12">
              <Card>
                <Form onSubmit={submitCaptionHandler}>
                  <CardHeader className="py-4 d-flex justify-content-end">
                    <Button
                      color=""
                      className="text-white"
                      style={{ backgroundColor: "#001753" }}
                      type="submit"
                    >
                      Submit
                    </Button>
                  </CardHeader>
                  <CardBody className="position-relative">
                    <SimpleMdeReact
                      id="editor_container"
                      onChange={inputHandler}
                      value={text}
                      options={{
                        autofocus: true,
                        spellChecker: false,
                        toolbar: false,
                      }}
                    />
                  </CardBody>
                </Form>
              </Card>
            </Col>
          </Row>
        </Container>
      </Layout>
    </>
  );
};

export default Caption;
