import { createSlice } from '@reduxjs/toolkit';

let initialState = {
    value: true,
};

const depositWithdrawNotiSlice = createSlice({
    name: 'count',
    initialState,
    reducers: {
        statusHandler: (state,action) => {
            state.value = action.payload
        }
    }
});

export const {statusHandler} = depositWithdrawNotiSlice.actions;
export default depositWithdrawNotiSlice.reducer;