import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { getMethod, postMethodFormData } from "../api";
import Breadcrumbs from "../layout/breadcrumb";
import Layout from "./Layout";
import SweetAlert from "sweetalert2";
import Loader from "../layout/loader/Loader";
import { POPUP_API, POPUP_UPDATE_API } from "../api/routes";
import { decodeData } from "../utils/Helper";

const PopupBox = () => {
  const [loading, setLoading] = useState(false);
  const [photo, setPhoto] = useState("");
  const [refr, setRefr] = useState(true);
  const [updatePopup, setUpdatePopup] = useState(false);
  const [popupImage, setPopupImage] = useState("");
  const [popupImageValidate, setPopupImageValidate] = useState("");
  const [active, setActive] = useState("");

  useEffect(() => {
    (async () => {
      setLoading(true);
      const token = decodeData(localStorage.getItem("y_g_n_d_a"));
      const res = await getMethod(
        `${POPUP_API}`, token.token
      );
      res && setLoading(false);
      if (res?.status === "success") {
        setPhoto(res?.data?.[0]?.popup);
        setActive(res?.data?.[0]?.status);
      }
    })();
  }, [refr]);

  const updateOpenModal = () => {
    setUpdatePopup(!updatePopup);
  };

  const updateSubmitHandler = async (e) => {
    e.preventDefault();
    setLoading(true);

    const formData = new FormData();
    formData.append("popup", popupImage);
    const token = decodeData(localStorage.getItem("y_g_n_d_a"));
    const res = await postMethodFormData(
      POPUP_UPDATE_API("1"),
      formData,
      token.token,
      "PUT"
    );
    if (res?.status === "success") {
      setLoading(false);
      setUpdatePopup(false);
      setRefr(!refr);
      return;
    } else {
      SweetAlert.fire({
        icon: "error",
        width: 300,
        title: "Sorry!",
        text: res?.message ?? "Something wrong, Try again!",
      });
      return;
    }
  };

  const popupToggler = async () => {
    const formData = new FormData();
    formData.append("status", active === "active" ? "disable" : "active");
    const token = decodeData(localStorage.getItem("y_g_n_d_a"));
    if (!token) return;
    setLoading(true);
    const res = await postMethodFormData(
      POPUP_UPDATE_API("1"),
      formData,
      token.token,
      "PUT"
    );
    res && setLoading(false);
    if (res?.status === "success") {
      setRefr(!refr);
      return;
    } else {
      SweetAlert.fire({
        icon: "error",
        width: 300,
        title: "Sorry!",
        text: res?.message ?? "Something wrong, Try again!",
      });
      return;
    }
  };

  return (
    <>
      <Layout>
        <Breadcrumbs title="Ads Popup" />
        <Container fluid={true}>
        {loading && <Loader />}
          <Row>
            <Col sm="12">
              <Card>
                <CardHeader className="py-4 d-flex justify-content-between">
                  <FormGroup switch>
                    <Input
                      type="switch"
                      className="bg-primary"
                      style={{
                        height: "20px",
                        width: "35px",
                        userSelect: "none",
                        cursor: "pointer",
                      }}
                      checked={active === "active" ? true : false}
                      onClick={popupToggler}
                    />
                  </FormGroup>
                  <Button
                    color=""
                    className="text-white btn btn-primary"
                    onClick={updateOpenModal}
                  >
                    Update
                  </Button>
                </CardHeader>
                <CardBody className="position-relative">
                  <div className="d-flex justify-content-center align-items-center">
                    {photo ? (
                      <img
                        src={photo}
                        alt="ads_popup"
                        style={{ maxWidth: "380px", borderRadius: "5px" }}
                      />
                    ) : (
                      <p
                        className="text-center text-danger fw-bold py-5"
                        style={{ fontSize: 20 }}
                      >
                        Ads Popup photo is disabled.
                      </p>
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </Layout>
      {
        <Modal isOpen={updatePopup} toggle={updateOpenModal} centered>
          <Form onSubmit={updateSubmitHandler}>
            <ModalHeader toggle={updateOpenModal}>Update Ads Popup</ModalHeader>
            <ModalBody>
              <FormGroup>
                <Label htmlFor="">Photo</Label>
                <Input
                  className="form-control"
                  type="file"
                  placeholder=""
                  onChange={(e) => {
                    setPopupImage(e.target.files[0]);
                    setPopupImageValidate("");
                  }}
                />
                {popupImageValidate && (
                  <div className="invalid-feedback d-block">
                    {popupImageValidate}
                  </div>
                )}
              </FormGroup>
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={updateOpenModal}>
                Close
              </Button>
              <Button color="primary" type="submit">
                Save
              </Button>
            </ModalFooter>
          </Form>
        </Modal>
      }
    </>
  );
};

export default PopupBox;
