import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  Input,
  Row,
  Table,
} from "reactstrap";
import { getMethod } from "../api";
import Breadcrumbs from "../layout/breadcrumb";
import Loader from "../layout/loader/Loader";
import Layout from "./Layout";
import DateRangeFormat from "../utils/DateRangeFormat";
import TableScroll from "../utils/TableScroll";
import { calculateProfit, calculateTotal, decodeData } from "../utils/Helper";
import { PROVIDER_API, PROVIDER_REPORT_API } from "../api/routes";
import moment from "moment";

const ReportProvider = () => {
  const [loading, setLoading] = useState(true);
  const [startDate, setStartDate] = useState(moment().format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));
  const [data, setData] = useState([]);
  const [reports, setReports] = useState([]);
  const [refr, setRefr] = useState(true);
  const [searchProvider, setSearchProvider] = useState('');
  const [providers, setProviders] = useState([]);


  useEffect(() => {
    (async () => {
      let res = await getMethod(`${PROVIDER_API}?dashboard=true`);
      res?.status === "success" && setProviders(res?.data);
    })();
  }, []);

  useEffect(() => {
    const token = decodeData(localStorage.getItem("y_g_n_d_a"));
    if (!token) return;
    (async () => {
        setLoading(true);
      const res = await getMethod(
        `${PROVIDER_REPORT_API}?start_date=${startDate}&end_date=${endDate}&site=${searchProvider}`,
        token.token
      );
      res && setLoading(false);
      if (res?.status === "success") {
        setData(res.data);
        return;
      }
    })();
  }, [refr]);

  useEffect(() => {
    if (providers?.length > 0 && data?.length > 0) {
      const newData = data.map((el) => {
        const provider = providers.find(
          (provider) => provider.name === el.site
        );
        if (provider) {
          el.site = provider.name
          return el;
        } else {
          return el;
        }
      });
      setReports(newData);
    } else {
      setReports(data);
    }
  }, [data, providers])

  const searchSubmitHandler = () => {
    setRefr((prev) => !prev);
  };

  return (
    <>
      <Layout>
        <Breadcrumbs title="Provider Report" />
        <Container fluid={true}>
        {loading && <Loader />}
          <Row>
            <Col sm="12">
              <Card>
                <CardHeader className="py-4">
                  <Col sm="12">
                    <Form>
                      <Row className="justify-content-end">
                        <Col md="4 px-1 mb-2">
                          <DateRangeFormat
                            setStartDate={setStartDate}
                            setEndDate={setEndDate}
                          />
                        </Col>
                        <Col md="4 px-1 mb-2">
                          <Input
                            type="select"
                            name="select"
                            className="form-control"
                            onChange={e => setSearchProvider(e.target.value)}
                          >
                            <option value="">Select Provider</option>
                            {providers?.length > 0 &&
                              providers?.map((el, index) => (
                                <option key={index} value={el.name}>
                                  {el.name}
                                </option>
                              ))}
                          </Input>
                        </Col>
                        <Col md="4 px-1 mb-2">
                          <button
                            className="w-100 btn text-white btn-primary"
                            disabled={loading}
                            type="button"
                            onClick={searchSubmitHandler}
                          >
                            {loading ? "Loading..." : "Submit"}
                          </button>
                        </Col>
                      </Row>
                    </Form>
                  </Col>
                </CardHeader>
                <CardBody className="position-relative">
                  <TableScroll>
                    <Table className="table-border-vertical">
                      <thead>
                        <tr className="bg-dark">
                          <th className="text-center text-white" scope="col">
                            <b>No.</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>Provider</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>Bet Amount</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>Bet Payout</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>Bet Profit</b>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {reports?.length > 0 &&
                          reports?.map((bal, i) => (
                            <tr key={i}>
                              <th className="text-center" scope="row">
                                {i + 1}.
                              </th>
                              <th className="text-center" scope="row">
                                {bal.site}
                              </th>
                              <td style={{ textAlign: "right" }}>
                                {bal.bet
                                  ? bal?.bet?.toLocaleString("en-us")
                                  : 0}
                              </td>
                              <td style={{ textAlign: "right" }}>
                                {bal.payout
                                  ? bal?.payout?.toLocaleString(
                                      "en-us"
                                    )
                                  : 0}
                              </td>
                              <td style={{ textAlign: "right" }}>
                                {(
                                  bal.bet - bal.payout
                                )?.toLocaleString("en-us")}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                      <tfoot>
                        <tr className="bg-dark">
                          <td colSpan={2} className="text-center text-white">
                            Total
                          </td>
                          <td className="text-end text-white">
                            {calculateTotal(data, "bet")}
                          </td>
                          <td className="text-end text-white">
                            {calculateTotal(data, "payout")}
                          </td>
                          <td className="text-end text-white">
                            {calculateProfit(
                              data,
                              "bet",
                              "payout"
                            )}
                          </td>
                        </tr>
                      </tfoot>
                    </Table>
                  </TableScroll>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </Layout>
    </>
  );
};

export default ReportProvider;
