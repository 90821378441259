import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardFooter,
  Col,
  Container,
  Row,
  Table,
} from "reactstrap";
import Breadcrumbs from "../layout/breadcrumb";
import Layout from "./Layout";
import Loader from "../layout/loader/Loader";
import { getMethod } from "../api";
import { useNavigate, useParams } from "react-router";
import Pagination from "../utils/Pagination";
import TableScroll from "../utils/TableScroll";
import { PROMOTION_REPORT_API } from "../api/routes";
import { decodeData } from "../utils/Helper";
const PromotionUsersDetail = () => {
  const [loading, setLoading] = useState(true);
  const [promotion, setPromotion] = useState([]);
  const [users, setUsers] = useState([]);
  const [from, setFrom] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [page, setPage] = useState(1);
  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      let token = decodeData(localStorage.getItem("y_g_n_d_a"));
      if (!token) return;
      let res = await getMethod(
        `${PROMOTION_REPORT_API}?promotion_id=${params.promotionId}&type=user`,
        token.token
      );
      res && setLoading(false);
      res?.status === "success" && setUsers(res?.data?.data);
      try {
        setPromotion(params?.promotionName?.replace(/%20/g, " "))
      } catch (error) {
        console.log('params error: ' , error)
        return navigate(-1)
      }
    })();
  }, []);
  return (
    <>
      <Layout>
        <Breadcrumbs title={`Promotion - ${params.promotionId}`} />
        <Container fluid={true}>
        {loading && <Loader />}
          <Row>
            <Col sm="12">
              <Card>
                <CardBody className="position-relative">
                  <TableScroll>
                    <Table className="table-border-vertical">
                      <thead>
                        <tr className="bg-dark">
                          <th className="text-center text-white" scope="col">
                            <b>{"No."}</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>{"User ID"}</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>{"Name"}</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>{"Reg Phone"}</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>{"Date and Time"}</b>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {users.length > 0 ? (
                          users.map((user, i) => (
                            <tr key={i} className="align-middle">
                              <th className="text-center" scope="row">{`${
                                i + from
                              }.`}</th>
                              <td className="text-center">{user.user_id}</td>
                              <td className="text-center">{user.user_name}</td>
                              <td className="text-center">{user.user_phone}</td>
                              <td className="text-center">{user.created_at}</td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={5} className="text-center">
                              No found user
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </TableScroll>
                </CardBody>
                {totalPage > 1 && (
                  <CardFooter className="d-flex justify-content-end">
                    <Pagination
                      setPage={setPage}
                      totalPage={totalPage}
                      setLoading={setLoading}
                    />
                  </CardFooter>
                )}
              </Card>
            </Col>
          </Row>
        </Container>
      </Layout>
    </>
  );
};

export default PromotionUsersDetail;
