import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
} from "reactstrap";
import { deleteMethod, getMethod, postMethod } from "../api";
import Breadcrumbs from "../layout/breadcrumb";
import Layout from "./Layout";
import SweetAlert from "sweetalert2";
import Loader from "../layout/loader/Loader";
import TableScroll from "../utils/TableScroll";
import { decodeData } from "../utils/Helper";
import { STREAMER_API, STREAMER_DELETE_API, STREAMER_UPDATE_API } from "../api/routes";

const CreateStreamer = () => {
  const [loading, setLoading] = useState(false);
  const [streamers, setStreamers] = useState([]);
  const [refr, setRefr] = useState(true);
  const [name, setName] = useState("");
  const [code, setCode] = useState("");
  const [status, setStatus] = useState("");
  const [nameValidate, setNameValidate] = useState("");
  const [codeValidate, setCodeValidate] = useState("");
  const [editStreamer, setEditStreamer] = useState(false);
  const [createStreamer, setCreateStreamer] = useState(false);
  const [streamerEditId, setStreamerEditId] = useState("");
  const [authRole, setAuthRole] = useState('');

  useEffect(() => {
    (async () => {
      const token = decodeData(localStorage.getItem("y_g_n_d_a"));
      if (!token) return;
      setAuthRole(token.user_role);
      setLoading(true);
      const res = await getMethod(STREAMER_API, token.token);
      res && setLoading(false);
      res?.status === "success" && setStreamers(res?.data);
    })();
  }, [refr]);

  const createOpenModal = () => {
    setCreateStreamer(!createStreamer);
    setName("");
    setCode("");
    setNameValidate("");
    setCodeValidate("");
  };

  const editOpenModal = () => {
    setEditStreamer(!editStreamer);
  };

  //   for create streamer handler
  const createSubmitHandler = async (e) => {
    e.preventDefault();
    if (!name) {
      setNameValidate("Name is required.");
      return;
    }
    if (!code) {
      setCodeValidate("Streamer code is required.");
      return;
    }
    const data = {
      name,
      code,
    };
    const token = decodeData(localStorage.getItem("y_g_n_d_a"));
    if (!token) return;
    setLoading(true);
    const res = await postMethod(STREAMER_API, data, token.token);
    res && setLoading(false);
    if (res?.status === "success") {
      SweetAlert.fire({
        icon: "success",
        width: 300,
        title: "Success!",
        text: "Create Streamer Successfully!",
      });
      createOpenModal();
      setRefr(!refr);
      return;
    } else {
      SweetAlert.fire({
        icon: "error",
        width: 300,
        title: "Sorry!",
        text:
          (res?.field ?? "") +
          " " +
          (res?.message ?? "Something wrong, Try again!"),
      });
      return;
    }
  };

  //   for edit streamer handler
  const editSubmitHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (streamerEditId) {
      setLoading(true);
      const formData = new FormData();
      const data = {
        name,
        code,
        status,
      };
      formData.append("name", name);
      formData.append("code", code);
      formData.append("status", status);
      const token = decodeData(localStorage.getItem("y_g_n_d_a"));
      const res = await postMethod(
        STREAMER_UPDATE_API(streamerEditId),
        data,
        token.token,
        "PUT"
      );
      res && setLoading(false);
      if (res?.status === "success") {
        SweetAlert.fire({
          icon: "success",
          width: 300,
          title: "Success!",
          text: "Update Streamer Successfully!",
        });
        editOpenModal();
        setRefr(!refr);
        setName("");
        setCode("");
        setNameValidate("");
        setCodeValidate("");
        return;
      } else {
        SweetAlert.fire({
          icon: "error",
          width: 300,
          title: "Sorry!",
          text:
            (res?.field ?? "") +
            " " +
            (res?.message ?? "Something wrong, Try again!"),
        });
        return;
      }
    }
  };

  const deleteHandler = async (id) => {
    const token = decodeData(localStorage.getItem("y_g_n_d_a"));
    if (!token) return;
    SweetAlert.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      width: 300,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true);
        (async () => {
          const res = await deleteMethod(
            STREAMER_DELETE_API(id),
            token.token
          );
          res && setLoading(false);
          if (res?.status === "success") {
            SweetAlert.fire({
              title: "Success!",
              text: "You has been deleted.",
              icon: "success",
              width: 300,
            });
            setRefr(!refr);
            return;
          } else {
            SweetAlert.fire({
              title: "Sorry!",
              text: res?.message ?? "Something wrong, Try again!",
              icon: "error",
              width: 300,
            });
            return;
          }
        })();
      }
    });
  };

  return (
    <>
      <Layout>
        <Breadcrumbs title="Streamers" />
        <Container fluid={true}>
          {loading && <Loader />}
          <Row>
            <Col sm="12">
              <Card>
                <CardHeader className="py-4 d-flex justify-content-end">
                  {
                    authRole === 'admin' && 
                    <Button color="info" onClick={createOpenModal}>
                      Create
                    </Button>
                  }
                </CardHeader>
                <CardBody className="position-relative">
                  <TableScroll>
                    <Table className="table-border-vertical">
                      <thead>
                        <tr className="bg-dark">
                          <th className="text-center text-white" scope="col">
                            <b>{"No."}</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>{"Name"}</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>{"Code"}</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>{"Status"}</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>{"Reg Date & Time"}</b>
                          </th>
                          {
                            (authRole === 'admin' || authRole === 'staff' || authRole === 'super') && 
                            <th className="text-center text-white" scope="col">
                              <b>{"Action"}</b>
                            </th>
                          }
                        </tr>
                      </thead>
                      <tbody>
                        {streamers?.length > 0 &&
                          streamers?.map((streamer, i) => (
                            <tr key={streamer.id} className="align-middle">
                              <td className="text-center">{`${++i}.`}</td>
                              <td className="text-center">{streamer.name}</td>
                              <td className="text-center">{streamer.code}</td>
                              <td className="text-center">
                                <span
                                  className={`badge bg-${
                                    streamer.status === "active"
                                      ? "success"
                                      : "danger"
                                  }`}
                                >
                                  {streamer.status}
                                </span>
                              </td>
                              <td className="text-center">
                                {streamer.created_at}
                              </td>
                              {
                                (authRole === 'admin' || authRole === 'staff' || authRole === 'super') &&
                              <td className="text-center">
                                <div>
                                  <span
                                    onClick={() => {
                                      editOpenModal();
                                      setStreamerEditId(streamer.id);
                                      setName(streamer.name);
                                      setCode(streamer.code);
                                      setStatus(streamer.status);
                                    }}
                                  >
                                    <i
                                      className="fa fa-pencil"
                                      style={{
                                        width: 35,
                                        fontSize: 18,
                                        padding: 11,
                                        color: "rgb(40, 167, 69)",
                                        cursor: "pointer",
                                      }}
                                    ></i>
                                  </span>
                                </div>
                              </td>
                              }
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  </TableScroll>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </Layout>
      {
        <Modal isOpen={createStreamer} toggle={createOpenModal} centered>
          <Form onSubmit={createSubmitHandler}>
            <ModalHeader toggle={createOpenModal}>Create Streamer</ModalHeader>
            <ModalBody>
              <FormGroup>
                <Label>Name</Label>
                <Input
                  className="form-control"
                  type="text"
                  placeholder="Name"
                  onChange={(e) => {
                    setName(e.target.value);
                    setNameValidate("");
                  }}
                />
                {nameValidate && (
                  <div className="invalid-feedback d-block">{nameValidate}</div>
                )}
              </FormGroup>
              <FormGroup>
                <Label>Code</Label>
                <Input
                  className="form-control"
                  type="text"
                  placeholder="Code"
                  onChange={(e) => {
                    setCode(e.target.value);
                    setCodeValidate("");
                  }}
                />
                {codeValidate && (
                  <div className="invalid-feedback d-block">{codeValidate}</div>
                )}
              </FormGroup>
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={createOpenModal}>
                Close
              </Button>
              <Button color="primary" type="submit">
                Save
              </Button>
            </ModalFooter>
          </Form>
        </Modal>
      }
      {
        <Modal isOpen={editStreamer} toggle={editOpenModal} centered>
          <Form onSubmit={editSubmitHandler}>
            <ModalHeader toggle={editOpenModal}>Edit Streamer</ModalHeader>
            <ModalBody>
              <FormGroup>
                <Label>Name</Label>
                <Input
                  className="form-control"
                  type="text"
                  value={name}
                  placeholder="Name"
                  onChange={(e) => {
                    setName(e.target.value);
                    setNameValidate("");
                  }}
                />
                {nameValidate && (
                  <div className="invalid-feedback d-block">{nameValidate}</div>
                )}
              </FormGroup>
              <FormGroup>
                <Label>Code</Label>
                <Input
                  className="form-control"
                  type="text"
                  value={code}
                  placeholder="Code"
                  onChange={(e) => {
                    setCode(e.target.value);
                    setCodeValidate("");
                  }}
                />
                {codeValidate && (
                  <div className="invalid-feedback d-block">{codeValidate}</div>
                )}
              </FormGroup>
              <Row>
                <Col>
                  <div className="mb-0">
                    <Label>Streamer Status</Label>
                    <Input
                      type="select"
                      name="select"
                      className="form-control form-control-sm digits"
                      onChange={(e) => {
                        setStatus(e.target.value);
                      }}
                      value={status}
                    >
                      <option value="">Choose</option>
                      <option value="active">Active</option>
                      <option value="disable">Disabled</option>
                    </Input>
                  </div>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={editOpenModal}>
                Close
              </Button>
              <Button color="primary" type="submit">
                Save
              </Button>
            </ModalFooter>
          </Form>
        </Modal>
      }
    </>
  );
};

export default CreateStreamer;
