import React from "react";
import styled from "styled-components";

export default function ToogleSwitch({status,event}) {
  const Switch = styled.label`
    position: relative;
    display: inline-block;
    width: 60px;
    height: 30px;
    margin: 0 auto;
  `;
  const InputSwitch = styled.input`
    display: none;
    &:checked + .slider {
      background-color: #2ab934;
    }
    &:focus + .slider {
      box-shadow: 0 0 1px #2196f3;
    }
    &:checked + .slider:before {
      -webkit-transform: translateX(26px);
      -ms-transform: translateX(26px);
      transform: translateX(30px);
    }
    &:checked + .slider:after {
      content: "ON";
      transform: translate(-37.5%, -25%);
      top: 37.5%;
      left: 25%;
    }
  `;

  const SliderDiv = styled.div`
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ca2222;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 6px;

    &:before {
      position: absolute;
      content: "";
      height: 27px;
      width: 27px;
      top: 1.5px;
      left: 1px;
      right: 1px;
      bottom: 1px;
      background-color: white;
      transition: 0.4s;
      border-radius: 6px;
    }
    &:after {
      content: "OFF";
      color: white;
      display: block;
      position: absolute;
      transform: translate(-60%, -75%);
      top: 60%;
      left: 75%;
      font-size: 10px;
      font-family: Verdana, sans-serif;
    }
  `;
  return (
    <Switch>
      <InputSwitch
        type="checkbox"
        checked={status}
        onChange={event}
        id="togBtn"
      />
      <SliderDiv className="slider"></SliderDiv>
    </Switch>
  );
}
