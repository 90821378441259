import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
} from "reactstrap";
import { deleteMethod, getMethod, postMethodFormData } from "../api";
import Breadcrumbs from "../layout/breadcrumb";
import Layout from "./Layout";
import SweetAlert from "sweetalert2";
import Loader from "../layout/loader/Loader";
import TableScroll from "../utils/TableScroll";
import {
  PROVIDER_API,
  PROVIDER_DELETE_API,
  PROVIDER_UPDATE_API,
} from "../api/routes";
import { currentDate, decodeData } from "../utils/Helper";
import ModalImage from "react-modal-image";

const Providers = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [refr, setRefr] = useState(true);
  const [editProvider, setEditProvider] = useState(false);
  const [createProvider, setCreateProvider] = useState(false);
  const [providerName, setProviderName] = useState("");
  const [providerImage, setProviderImage] = useState("");
  const [providerCode, setProviderCode] = useState("");
  const [providerNameValidate, setProviderNameValidate] = useState("");
  const [providerImageValidate, setProviderImageValidate] = useState("");
  const [providerCodeValidate, setProviderCodeValidate] = useState("");
  const [providerEditid, setProviderEditid] = useState("");
  const [status, setStatus] = useState("");
  const [authRole, setAuthRole] = useState('');
  const [sort, setSort] = useState('');

  useEffect(() => {
    (async () => {
      const token = decodeData(localStorage.getItem('y_g_n_d_a'));
      setAuthRole(token.user_role)
      if (!token) return;
      setLoading(true);
      const res = await getMethod(`${PROVIDER_API}?website=true&dashboard=true`, token.token);
      res && setLoading(false);
      res?.status === "success" && setData(res?.data);
    })();
  }, [refr]);

  const editOpenModal = (data = {}) => {
    setEditProvider(!editProvider);
    setProviderName(data?.name ?? "");
    setProviderCode(data?.code ?? "");
    setProviderImage("");
    setProviderNameValidate("");
    setProviderCodeValidate("");
    setProviderImageValidate("");
    setSort(data?.sort || '');
    setStatus(data?.status ?? "");
  };

  const createOpenModal = () => {
    setCreateProvider(!createProvider);
    setProviderName("");
    setProviderCode("");
    setProviderImage("");
    setProviderNameValidate("");
    setProviderCodeValidate("");
    setProviderImageValidate("");
    setSort('');
    setStatus("");
  };

  const statusHandler = (e) => {
    setStatus(e.target.value);
  };

  const providerCodeHandler = (e) => {
    setProviderCode(e.target.value);
    setProviderCodeValidate("");
  };

  const createSubmitHandler = async (e) => {
    e.preventDefault();
    if (!providerCode) {
      setProviderCodeValidate("Provider code is required.");
      return;
    }
    if (!providerName) {
      setProviderNameValidate("Provider name is required.");
      return;
    }
    if (!providerImage) {
      setProviderImageValidate("Provider image is required.");
      return;
    }
    const formData = new FormData();
    formData.append("code", providerCode);
    formData.append("name", providerName);
    formData.append("photo", providerImage);

    const token = decodeData(localStorage.getItem("y_g_n_d_a"));
    if (!token) return;
    setLoading(true);
    const res = await postMethodFormData(PROVIDER_API, formData, token.token);
    res && setLoading(false);
    if (res?.status === "success") {
      setCreateProvider(false);
      setRefr(!refr);
      setProviderName("");
      setProviderImage("");
      setProviderCode("");
      return;
    } else {
      SweetAlert.fire({
        icon: "error",
        width: 300,
        title: "Sorry!",
        text:
          (res?.field ?? "") +
          " " +
          (res?.message ?? "Something wrong, Try again!"),
      });
      return;
    }
  };
  const editSubmitHandler = async () => {
    if (!providerEditid) return;

    const formData = new FormData();
    formData.append("name", providerName);
    formData.append("photo", providerImage);
    formData.append("status", status);
    formData.append("sort", sort);
    const token = decodeData(localStorage.getItem("y_g_n_d_a"));
    if (!token) return;
    setLoading(true);
    const res = await postMethodFormData(
      `${PROVIDER_UPDATE_API(providerEditid)}?updated_at=${currentDate()}`,
      formData,
      token.token,
      'PUT'
    );
    res && setLoading(false);
    if (res?.status === "success") {
      setEditProvider(false);
      setRefr(!refr);
      setProviderName("");
      setProviderImage("");
      setProviderCode("");
      setSort('');
      return;
    } else {
      SweetAlert.fire({
        icon: "error",
        width: 300,
        title: "Sorry!",
        text:
          (res?.field ?? "") +
          " " +
          (res?.message ?? "Something wrong, Try again!"),
      });
      return;
    }
  };

  const deleteHandler = async (id) => {
    const token = decodeData(localStorage.getItem("y_g_n_d_a"));
    if (!token) return;
    SweetAlert.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      width: 300,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true);
        (async () => {
          const res = await deleteMethod(PROVIDER_DELETE_API(id), token.token);
          res && setLoading(false);
          if (res?.status === "success") {
            SweetAlert.fire({
              title: "Success!",
              text: "You has been deleted.",
              icon: "success",
              width: 300,
            });
            setRefr(!refr);
            return;
          } else {
            SweetAlert.fire({
              title: "Sorry!",
              text: res?.message ?? "Something wrong, Try again!",
              icon: "error",
              width: 300,
            });
            return;
          }
        })();
      }
    });
  };

  return (
    <>
      <Layout>
        <Breadcrumbs title="Providers" />
        <Container fluid={true}>
        {loading && <Loader />}
          <Row>
            <Col sm="12">
              <Card>
                {/* <CardHeader className="py-4 d-flex justify-content-end">
                  {
                    authRole === 'admin' && 
                    <Button
                      color=""
                      style={{ backgroundColor: "#001753" }}
                      className="text-white"
                      onClick={createOpenModal}
                    >
                      Create
                    </Button>
                  }
                </CardHeader> */}
                <CardBody className="position-relative">
                  <TableScroll>
                    <Table className="table-border-vertical">
                      <thead>
                        <tr className="bg-dark">
                          <th className="text-center text-white" scope="col">
                            <b>No.</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>Photo</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>Provider</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>MMK</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>Status</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>Sort</b>
                          </th>
                          {
                            (authRole === 'admin' || authRole === 'staff' || authRole === 'super') &&
                            <th className="text-center text-white" scope="col">
                              <b>Action</b>
                            </th>
                          }
                        </tr>
                      </thead>
                      <tbody>
                        {data?.length > 0 &&
                          data.map((el, i) => (
                              <tr key={i} className="align-middle">
                                <th className="text-center" scope="row">{`${
                                  i + 1
                                }.`}</th>
                                <td className="text-center">
                                  <div
                                  style={{
                                    width: "60px",
                                    borderRadius: "5px",
                                    overflow: "hidden",
                                    margin: "auto",
                                  }}
                                >
                                  <ModalImage
                                    alt={el.name}
                                    small={el.photo}
                                    large={el.photo}
                                  />
                                </div>
                                </td>
                                <td className="text-center">{el?.name}</td>
                                <td style={{ textAlign: "right" }}>
                                  {el.product_unit
                                    ? el?.product_unit?.toLocaleString(
                                      "en-us"
                                    )
                                    : 0}
                                </td>
                                <td className="text-center">
                                  {el.status === "active" ? (
                                    <i className="fa fa-circle font-success f-12" />
                                  ) : (
                                    <i className="fa fa-circle font-danger f-12" />
                                  )}
                                </td>
                                <td className="text-center">{el?.sort}</td>
                                {
                                  (authRole === 'admin' || authRole === 'staff' || authRole === 'super') && 
                                <td className="text-center">
                                  <div>
                                    <span
                                      onClick={() => {
                                        editOpenModal(el);
                                        setProviderEditid(el.id);
                                      }}
                                    >
                                      <i
                                        className="fa fa-pencil font-success"
                                        style={{
                                          width: 35,
                                          fontSize: 18,
                                          padding: 11,
                                          cursor: "pointer",
                                        }}
                                      ></i>
                                    </span>
                                  </div>
                                </td>
                                }
                              </tr>
                            ))}
                      </tbody>
                    </Table>
                  </TableScroll>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </Layout>
      {
        <Modal isOpen={createProvider} toggle={createOpenModal} centered>
          <Form onSubmit={createSubmitHandler}>
            <ModalHeader toggle={createOpenModal}>Create Provider</ModalHeader>
            <ModalBody>
              <FormGroup>
                <Label htmlFor="">Provider Code</Label>
                <Input
                  type="select"
                  name="select"
                  className="form-control"
                  onChange={providerCodeHandler}
                >
                  <option value="">Choose</option>
                  {data?.length > 0 &&
                    data?.map((el, i) => (
                      <option key={el.code} value={el.name}>
                        {el.name}
                      </option>
                    ))}
                </Input>
                {providerCodeValidate && (
                  <div className="invalid-feedback d-block">
                    {providerCodeValidate}
                  </div>
                )}
              </FormGroup>
              <FormGroup>
                <Label htmlFor="">Provider Name</Label>
                <Input
                  className="form-control"
                  type="text"
                  defaultValue={providerName}
                  placeholder="Provider Name"
                  onChange={(e) => {
                    setProviderName(e.target.value);
                    setProviderNameValidate("");
                  }}
                />
                {providerNameValidate && (
                  <div className="invalid-feedback d-block">
                    {providerNameValidate}
                  </div>
                )}
              </FormGroup>
              <FormGroup>
                <Label htmlFor="">Photo</Label>
                <Input
                  className="form-control"
                  type="file"
                  placeholder=""
                  onChange={(e) => {
                    setProviderImage(e.target.files[0]);
                    setProviderImageValidate("");
                  }}
                />
                {providerImageValidate && (
                  <div className="invalid-feedback d-block">
                    {providerImageValidate}
                  </div>
                )}
              </FormGroup>
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={createOpenModal}>
                Close
              </Button>
              <Button color="primary" type="submit">
                Save
              </Button>
            </ModalFooter>
          </Form>
        </Modal>
      }
      {
        <Modal isOpen={editProvider} toggle={editOpenModal} centered>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              editSubmitHandler();
            }}
          >
            <ModalHeader toggle={editOpenModal}>Edit Provider</ModalHeader>
            <ModalBody>
              {/* <FormGroup>
                <Label htmlFor="">Provider Code</Label>
                <Input
                  type="select"
                  name="select"
                  className="form-control"
                  onChange={providerCodeHandler}
                  value={providerCode}
                >
                  <option value="">Choose</option>
                  {data?.length > 0 &&
                    data?.map((el, i) => (
                      <option key={el.code} value={el.name}>
                        {el.name}
                      </option>
                    ))}
                </Input>
                {providerCodeValidate && (
                  <div className="invalid-feedback d-block">
                    {providerCodeValidate}
                  </div>
                )}
              </FormGroup> */}

              <FormGroup>
                <Label htmlFor="">Provider Name</Label>
                <Input
                  className="form-control"
                  type="text"
                  defaultValue={providerName}
                  placeholder="Provider Name"
                  onChange={(e) => {
                    setProviderName(e.target.value);
                    setProviderNameValidate("");
                  }}
                />
                {providerNameValidate && (
                  <div className="invalid-feedback d-block">
                    {providerNameValidate}
                  </div>
                )}
              </FormGroup>
              <FormGroup>
                <Label htmlFor="">Photo</Label>
                <Input
                  className="form-control"
                  type="file"
                  placeholder=""
                  onChange={(e) => {
                    setProviderImage(e.target.files[0]);
                    setProviderImageValidate("");
                  }}
                />
                {providerImageValidate && (
                  <div className="invalid-feedback d-block">
                    {providerImageValidate}
                  </div>
                )}
              </FormGroup>
              <Row>
                <Col>
                  <div className="mb-0">
                    <Label>Payment Status</Label>
                    <Input
                      type="select"
                      name="select"
                      className="form-control form-control-sm digits"
                      onChange={statusHandler}
                      value={status}
                    >
                      <option value="">Choose</option>
                      <option value="active">Active</option>
                      <option value="disable">Disabled</option>
                    </Input>
                  </div>
                </Col>
              </Row>
              <FormGroup>
                <Label htmlFor="">Sort</Label>
                <Input
                  className="form-control"
                  type="text"
                  defaultValue={sort}
                  placeholder="Sort"
                  onChange={(e) => {
                    setSort(e.target.value);
                  }}
                />
              </FormGroup>
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={editOpenModal}>
                Close
              </Button>
              <Button color="primary" type="submit">
                Save
              </Button>
            </ModalFooter>
          </Form>
        </Modal>
      }
    </>
  );
};

export default Providers;
