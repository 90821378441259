import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
} from "reactstrap";
import { getMethod, postMethod } from "../api";
import Breadcrumbs from "../layout/breadcrumb";
import Layout from "./Layout";
import SweetAlert from "sweetalert2";
import Loader from "../layout/loader/Loader";
import TableScroll from "../utils/TableScroll";
import { decodeData } from "../utils/Helper";
import { LUCKY_DRAWS_API, LUCKY_DRAWS_UPDATE_API } from "../api/routes";

const LuckyDraw = () => {
  const [loading, setLoading] = useState(false);
  const [streamers, setStreamers] = useState([]);
  const [refr, setRefr] = useState(true);
  const [name, setName] = useState("");
  const [prize, setPrize] = useState("");
  const [win, setWin] = useState("");
  const [editStreamer, setEditStreamer] = useState(false);
  const [streamerEditId, setStreamerEditId] = useState("");
  const [authRole, setAuthRole] = useState('');

  useEffect(() => {
    (async () => {
      const token = decodeData(localStorage.getItem("y_g_n_d_a"));
      if (!token) return;
      setAuthRole(token.user_role);
      setLoading(true);
      const res = await getMethod(LUCKY_DRAWS_API, token.token);
      res && setLoading(false);
      res?.status === "success" && setStreamers(res?.data);
    })();
  }, [refr]);

  const editOpenModal = () => {
    setEditStreamer(!editStreamer);
  };

  //   for edit lucky draw handler
  const editSubmitHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (streamerEditId) {
      setLoading(true);
      const data = {
        name,
        price: prize,
        win,
      };
      const token = decodeData(localStorage.getItem("y_g_n_d_a"));
      const res = await postMethod(
        LUCKY_DRAWS_UPDATE_API(streamerEditId),
        data,
        token.token,
        "PUT"
      );
      res && setLoading(false);
      if (res?.status === "success") {
        SweetAlert.fire({
          icon: "success",
          width: 300,
          title: "Success!",
          text: res?.message,
        });
        editOpenModal();
        setRefr(!refr);
        setName("");
        setPrize("");
        setWin("");
        return;
      } else {
        SweetAlert.fire({
          icon: "error",
          width: 300,
          title: "Sorry!",
          text:
            (res?.field ?? "") +
            " " +
            (res?.message ?? "Something wrong, Try again!"),
        });
        return;
      }
    }
  };

  return (
    <>
      <Layout>
        <Breadcrumbs title="Lucky Draws" />
        <Container fluid={true}>
          {loading && <Loader />}
          <Row>
            <Col sm="12">
              <Card>
                <CardBody className="position-relative">
                  <TableScroll>
                    <Table className="table-border-vertical">
                      <thead>
                        <tr className="bg-dark">
                          <th className="text-center text-white" scope="col">
                            <b>{"No."}</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>{"Name"}</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>{"Prize"}</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>{"Status"}</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>{"Win"}</b>
                          </th>
                          {
                            (authRole === 'admin' || authRole === 'staff' || authRole === 'super') && 
                            <th className="text-center text-white" scope="col">
                              <b>{"Action"}</b>
                            </th>
                          }
                           <th className="text-center text-white" scope="col">
                            <b>{"Updated At"}</b>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {streamers?.length > 0 &&
                          streamers?.map((streamer, i) => (
                            <tr key={streamer.id} className="align-middle">
                              <td className="text-center">{`${++i}.`}</td>
                              <td className="text-center">{streamer.name}</td>
                              <td className="text-center">{streamer.price}</td>
                              <td className="text-center">{streamer.status}</td>
                              <td className="text-center">{streamer.win}</td>
                              {
                                (authRole === 'admin' || authRole === 'staff' || authRole === 'super') &&
                              <td className="text-center">
                                <div className="d-flex justify-content-center align-items-center">
                                  <span
                                    onClick={() => {
                                      editOpenModal();
                                      setStreamerEditId(streamer.id);
                                      setName(streamer.name);
                                      setPrize(streamer?.price);
                                      setWin(streamer?.win);
                                    }}
                                  >
                                    <i
                                      className="fa fa-pencil"
                                      style={{
                                        width: 35,
                                        fontSize: 18,
                                        padding: 11,
                                        color: "rgb(40, 167, 69)",
                                        cursor: "pointer",
                                      }}
                                    ></i>
                                  </span>
                                </div>
                              </td>
                              }
                              <td className="text-center">
                                {streamer.updated_at}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  </TableScroll>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </Layout>
      {
        <Modal isOpen={editStreamer} toggle={editOpenModal} centered>
          <Form onSubmit={editSubmitHandler}>
            <ModalHeader toggle={editOpenModal}>Edit Lucky Draw</ModalHeader>
            <ModalBody>
              <FormGroup>
                <Label>Name</Label>
                <Input
                  className="form-control"
                  type="text"
                  value={name}
                  placeholder="Name"
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                />
              </FormGroup>
              <FormGroup>
                <Label>Prize</Label>
                <Input
                  className="form-control"
                  type="text"
                  value={prize}
                  placeholder="Prize"
                  onChange={(e) => {
                    setPrize(e.target.value);
                  }}
                />
              </FormGroup>
              <Row>
                <Col>
                  <div className="mb-0">
                    <Label>Win</Label>
                    <Input
                      type="select"
                      name="select"
                      className="form-control form-control-sm digits"
                      onChange={(e) => {
                        setWin(e.target.value);
                      }}
                      value={win}
                    >
                      <option value="">Choose</option>
                      <option value="0">0</option>
                      <option value="1">1</option>
                    </Input>
                  </div>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={editOpenModal}>
                Close
              </Button>
              <Button color="primary" type="submit">
                Save
              </Button>
            </ModalFooter>
          </Form>
        </Modal>
      }
    </>
  );
};

export default LuckyDraw;
