import React, { Fragment } from "react";
import man from "../../assets/images/dashboard/profile.jpg";
import { LogIn, Maximize } from "react-feather";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { getMethod, postMethod } from "../../api";
import { decodeData } from "../../utils/Helper";
import { LOGOUT_API, USER_INFO_API } from "../../api/routes";
import { useDispatch, useSelector } from "react-redux";
import { userInfoHandler } from "../../store/slices/userInfoSlice";

const Rightbar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user_info } = useSelector((state) => state.userInfoSlice);

  useEffect(() => {
    if (user_info?.name) return;
    const token = decodeData(localStorage.getItem("y_g_n_d_a"));
    if (token) {
      (async () => {
        const res = await getMethod(
          `${USER_INFO_API}/${token.user_id}`,
          token.token
        );
        res?.status === "success" && dispatch(userInfoHandler(res?.data));
      })();
    }
  }, [user_info]);

  function goFull() {
    if (
      (document.fullScreenElement && document.fullScreenElement !== null) ||
      (!document.mozFullScreen && !document.webkitIsFullScreen)
    ) {
      if (document.documentElement.requestFullScreen) {
        document.documentElement.requestFullScreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullScreen) {
        document.documentElement.webkitRequestFullScreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

  const logoutHandler = async () => {
    const token = decodeData(localStorage.getItem("y_g_n_d_a"));
    localStorage.removeItem("y_g_n_d_a");
    navigate("/admin/login");
    return;
    // if (token) {
    //   const res = await postMethod(LOGOUT_API, {}, token.token);
    //   if (res?.status === "success") {
    //     localStorage.removeItem("y_g_n_d_a");
    //     navigate("/admin/login");
    //     return;
    //   }
    // }
  };

  return (
    <Fragment>
      <div className="nav-right col-8 pull-right right-header p-0">
        <ul className="nav-menus">
          <li className="maximize">
            <a className="text-dark" href="#javascript" onClick={goFull}>
              <Maximize />
            </a>
          </li>
          <li className="profile-nav onhover-dropdown p-0">
            <div className="media profile-media">
              <img className="b-r-10" src={man} alt="user" />
              <div className="media-body">
                <span>{user_info?.name}</span>
                <p className="mb-0 font-roboto">
                  {user_info?.role} <i className="middle fa fa-angle-down"></i>
                </p>
              </div>
            </div>
            <ul className="profile-dropdown onhover-show-div">
              <li onClick={logoutHandler}>
                <LogIn />
                <span>Logout</span>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </Fragment>
  );
};
export default Rightbar;
