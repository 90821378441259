import React, { useState } from "react";
import Breadcrumb from "../layout/breadcrumb";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Input,
  FormGroup,
  Button,
  Form,
  Label,
} from "reactstrap";
import Layout from "./Layout";
import { createPortal } from "react-dom";
import Loader from "../layout/loader/Loader";
import { postMethod } from "../api";
import SweetAlert from "sweetalert2";
import { currentDate, decodeData } from "../utils/Helper";
import { CHANGE_SELF_PASSWORD_API } from "../api/routes";
import { useNavigate } from "react-router";

const ChangeSelfPassword = () => {
  const [loading, setLoading] = useState(false);
  const [newPass, setNewPass] = useState("");
  const [conPass, setConPass] = useState("");
  const [newValidate, setNewValidate] = useState("");
  const [conValidate, setConValidate] = useState("");
  const navigate = useNavigate();

  const submitHandler = async (e) => {
    e.preventDefault();
    const token = decodeData(localStorage.getItem("y_g_n_d_a"));
    if (!newPass) {
        setNewValidate("Password is required.");
        return;
    }
    console.log(newPass)
    if (newPass.length < 8) {
        setNewValidate('Password must be at least 8 characters.');
        return;
    }
    if (!conPass) {
        setConValidate('Confirm password is required.')
        return;
    }
    if (newPass !== conPass) {
        setConValidate('Password does dont match.')
        return;
    }
    if (!token) return;
    const data = {
      user_id: token.user_id,
      password: newPass,
      password_confirmation: conPass,
    };
    setLoading(true);
    const res = await postMethod(`${CHANGE_SELF_PASSWORD_API}?updated_at=${currentDate()}`, data, token.token);
    res && setLoading(false);
    if (res?.status === "success") {
      SweetAlert.fire({
        icon: "success",
        width: 300,
        title: "Success",
        text: "Password changed successfully",
      });
      localStorage.removeItem('y_g_n_d_a');
      navigate('/admin/login');
      setConPass("");
      setNewPass("");
    } else {
      SweetAlert.fire({
        icon: "error",
        width: 300,
        title: "Error",
        text: res?.message ?? "Something wrong, Try again!",
      });
    }
  };

  return (
    <>
      <Layout>
        <Breadcrumb title="Change Self Password" />
        <Container fluid={true}>
        {loading && <Loader />}
          <Row>
            <Card>
              <Row>
                <Col sm="12">
                  <Form className="theme-form" onSubmit={submitHandler}>
                    <CardBody className="position-relative">
                      <Row>
                        <Col md="6">
                          <FormGroup>
                            <Label className="col-form-label pt-0">
                              New Password
                            </Label>
                            <Input
                              className="form-control"
                              value={newPass}
                              type="text"
                              placeholder="Enter New Password"
                              onChange={(e) => {
                                setNewPass(e.target.value);
                                setNewValidate("");
                              }}
                            />
                            {newValidate && (
                              <div className="invalid-feedback d-block">
                                {newValidate}
                              </div>
                            )}
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup>
                            <Label className="col-form-label pt-0">
                              Confirm Password
                            </Label>
                            <Input
                              className="form-control"
                              value={conPass}
                              type="text"
                              placeholder="Enter Confirm Password"
                              onChange={(e) => {
                                setConPass(e.target.value);
                                setConValidate("");
                              }}
                            />
                            {conValidate && (
                              <div className="invalid-feedback d-block">
                                {conValidate}
                              </div>
                            )}
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup>
                            <Label className="col-form-label pt-0">
                              Submit
                            </Label>
                            <Button
                              style={{
                                width: "100%",
                                backgroundColor: "#001753",
                              }}
                              color=""
                              type="submit"
                              className="me-1 text-white"
                            >
                              Submit
                            </Button>
                          </FormGroup>
                        </Col>
                      </Row>
                    </CardBody>
                  </Form>
                </Col>
              </Row>
            </Card>
          </Row>
        </Container>
      </Layout>
    </>
  );
};

export default ChangeSelfPassword;
