import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Form,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Table,
} from "reactstrap";
import { getMethod } from "../api";
import Breadcrumbs from "../layout/breadcrumb";
import Loader from "../layout/loader/Loader";
import Layout from "./Layout";
import Pagination from "../utils/Pagination";
import DateRangeFormat from "../utils/DateRangeFormat";
import TableScroll from "../utils/TableScroll";
import { calculateTotal, decodeData } from "../utils/Helper";
import { BETTING_HISTORY_API } from "../api/routes";
import moment from "moment";

const BettingBody = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [totalPage, setTotalPage] = useState(0);
  const [from, setFrom] = useState(1);
  const [page, setPage] = useState(1);
  const [showDetail, setShowDetail] = useState(false);
  const [detailData, setDetailData] = useState("");
  const [betDetail, setBetDetail] = useState([]);
  const [startDate, setStartDate] = useState(moment().format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));
  const [refr, setRefr] = useState(true);

  useEffect(() => {
    setLoading(true);
    const token = decodeData(localStorage.getItem("y_g_n_d_a"));
    if (!token) return;
    setLoading(true);
    (async () => {
      const res = await getMethod(
        `${BETTING_HISTORY_API}?product=SB&start_date=${startDate}&end_date=${endDate}&bet_type=BODY&page=${page}`,
        token.token
      );

      res && setLoading(false);
      if (res?.status === "success") {
        setData(res.data);
        res?.meta?.from && setFrom(res?.meta?.from);

        res?.meta?.last_page && setTotalPage(res?.meta?.last_page);
      }
    })();
  }, [refr, page]);

  const searchSubmitHandler = () => {
    setRefr((prev) => !prev);
  };

  const detailModal = (data) => {
    setShowDetail(!showDetail);
  };

  useEffect(() => {
    let result = detailData.split("-[").map((el) => {
      return el.split("]")[1];
    });
    result?.map((el, i) => {
      if (el === "") {
        result.splice(i, 1);
      }
    });
    setBetDetail(result);
  }, [detailData]);

  return (
    <>
      <Layout>
        <Breadcrumbs title="Betting Body" />
        <Container fluid={true}>
        {loading && <Loader />}
          <Row>
            <Col sm="12">
              <Card>
                <CardHeader className="py-4 d-flex justify-content-end">
                  <Col sm="12">
                    <Form>
                      <Row className="justify-content-end">
                        <Col md="4 px-1 mb-2">
                          <DateRangeFormat
                            setStartDate={setStartDate}
                            setEndDate={setEndDate}
                          />
                        </Col>
                        <Col md="4 px-1 mb-2">
                          <button
                            className="w-100 btn text-white"
                            style={{ backgroundColor: "#001753" }}
                            disabled={loading}
                            type="button"
                            onClick={searchSubmitHandler}
                          >
                            {loading ? "Loading..." : "Submit"}
                          </button>
                        </Col>
                      </Row>
                    </Form>
                  </Col>
                </CardHeader>
                <CardBody className="position-relative">
                  <TableScroll>
                    <Table className="table-border-vertical">
                      <thead>
                        <tr className="bg-dark">
                          <th className="text-center text-white" scope="col">
                            <b>{"No."}</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>{"Name"}</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>{"Reg Phone"}</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>{"Ref No"}</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>{"Bet Time"}</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>{"Play Time"}</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>{"Bet Type"}</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>{"Bet Status"}</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>{"Status"}</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>{"Win / Lose"}</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>{"Bet"}</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>{"Payout"}</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>{"Comm:"}</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>{"Profit"}</b>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {data?.length > 0 &&
                          data?.map((row, i) => (
                            <tr key={i}>
                              <th className="text-center" scope="row">{`${
                                i + from
                              }.`}</th>
                              <td className="text-center">{row.user_name}</td>
                              <td className="text-center">{row.user_phone}</td>
                              <td className="text-center">{row.ref_no}</td>
                              <td className="text-center">{row.start_time}</td>
                              <td className="text-center">{row.match_time}</td>
                              <td className="text-center">
                                <span
                                  onClick={() => {
                                    detailModal();
                                    setDetailData(row.bet_detail);
                                  }}
                                  style={{
                                    fontWeight: "bolder",
                                    cursor: "pointer",
                                    userSelect: "none",
                                  }}
                                >
                                  {row.bet_type}
                                </span>
                              </td>
                              <td className="text-center">
                                <span
                                  className={`badge badge-${
                                    row.bet_status === "Accept"
                                      ? "primary"
                                      : row.bet_status === "END"
                                      ? "success"
                                      : "danger"
                                  }`}
                                >
                                  {row.bet_status}
                                </span>
                              </td>
                              <td className="text-center">
                                <span
                                  className={`badge badge-${
                                    row.status === "Waiting"
                                      ? "warning"
                                      : "dark"
                                  }`}
                                >
                                  {row.status}
                                </span>
                              </td>
                              <td className="text-center">
                                <span
                                  className={`badge badge-${
                                    row.win_lose === "LOSE"
                                      ? "danger"
                                      : row.win_lose === "WIN"
                                      ? "success"
                                      : "primary"
                                  }`}
                                >
                                  {row.win_lose}
                                </span>
                              </td>
                              <td className="text-end">
                                {row.bet
                                  ? row?.bet?.toLocaleString("en-us")
                                  : 0}
                              </td>
                              <td className="text-end">
                                {row.payout
                                  ? row?.payout?.toLocaleString("en-us")
                                  : 0}
                              </td>
                              <td className="text-end">
                                {row.commission
                                  ? row?.commission?.toLocaleString("en-us")
                                  : 0}
                              </td>
                              <td className="text-end">
                                {row.win_lose_amount
                                  ? row?.win_lose_amount?.toLocaleString(
                                      "en-us"
                                    )
                                  : 0}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                      <tfoot>
                        <tr className="bg-dark">
                          <td colSpan={10} className="text-end text-white">
                            Total
                          </td>
                          <td className="text-end text-white">
                            {calculateTotal(data, "bet")}
                          </td>
                          <td className="text-end text-white">
                            {calculateTotal(data, "payout")}
                          </td>
                          <td className="text-end text-white">
                            {calculateTotal(data, "commission")}
                          </td>
                          <td className="text-end text-white">
                            {calculateTotal(data, "win_lose_amount")}
                          </td>
                        </tr>
                      </tfoot>
                    </Table>
                  </TableScroll>
                </CardBody>
                <CardFooter className="d-flex justify-content-end">
                  <Pagination
                    setPage={setPage}
                    totalPage={totalPage}
                    setLoading={setLoading}
                  />
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </Container>
      </Layout>
      {
        <Modal isOpen={showDetail} toggle={detailModal} centered>
          <ModalHeader toggle={detailModal}>Bet Detail</ModalHeader>
          <ModalBody>
            <ul>
              {betDetail?.length > 0 &&
                betDetail?.map((detail, i) => (
                  <li key={i}>{detail}</li>
                ))}
            </ul>
          </ModalBody>
        </Modal>
      }
    </>
  );
};

export default BettingBody;
