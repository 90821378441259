import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Input,
  Row,
  Table,
} from "reactstrap";
import { getMethodAuth, postMethod, postMethodWithoutData } from "../api";
import Breadcrumbs from "../layout/breadcrumb";
import Loader from "../layout/loader/Loader";
import Layout from "./Layout";
import SweetAlert from "sweetalert2";
import Pagination from "../utils/Pagination";
import { statusHandler } from "../store/slices/depositWithdrawNotiSlice";
import { useDispatch, useSelector } from "react-redux";
import DateRangeFormat from "../utils/DateRangeFormat";
import TableScroll from "../utils/TableScroll";
import { currentDate, decodeData } from "../utils/Helper";
import { WITHDRAW_API, WITHDRAW_APPROVE_API, WITHDRAW_REJECT_API } from "../api/routes";
import moment from "moment";

const Withdraw = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [startDate, setStartDate] = useState(moment().format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));
  const [confirm, setConfirm] = useState(true);
  const [totalPage, setTotalPage] = useState(1);
  const [from, setFrom] = useState(1);
  const [page, setPage] = useState(1);
  const [searchName, setSearchName] = useState("");
  const [searchId, setSearchId] = useState("");
  const [status, setStatus] = useState("");
  const [withdrawTotal, setWithdrawTotal] = useState(0);
  const { value } = useSelector((state) => state.notiCount);
  const dispatch = useDispatch();
  const [refr, setRefr] = useState(true);
  const [role, setRole] = useState("");
  const { refresh } = useSelector(state => state.refresh);

  useEffect(() => {
    const token = decodeData(localStorage.getItem("y_g_n_d_a"));
    if (!token) return;
    setRole(token?.user_role);
    (async () => {
      setLoading(true);
      const res = await getMethodAuth(
        `${WITHDRAW_API}?status=${status}&start_date=${startDate}&end_date=${endDate}&name=${searchName}&user_id=${searchId}&page=${page}`,
        token.token
      );
      res && setLoading(false);
      if (res.status === "success") {
        setData([]);
        setData(res.data);
        res?.meta?.from && setFrom(res?.meta?.from);
        res?.meta?.last_page && setTotalPage(res?.meta?.last_page);
      }
    })();
  }, [refr, confirm, page, refresh]);

  const approveHandler = async (id) => {
    const token = decodeData(localStorage.getItem("y_g_n_d_a"));
    if (!token) return;
    SweetAlert.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      width: 300,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Approve",
    }).then((result) => {
      if (result.isConfirmed) {
        (async () => {
          setLoading(true);
          const res = await postMethodWithoutData(
            `${WITHDRAW_APPROVE_API(id)}?updated_at=${currentDate()}`,
            token.token
          );
          res && setLoading(false);
          if (res.status === "success") {
            SweetAlert.fire({
              title: "Approved!",
              text: "You has been saved.",
              icon: "success",
              width: 300,
            });
            setConfirm(!confirm);
            dispatch(statusHandler(!value));
            return;
          } else {
            SweetAlert.fire({
              icon: "error",
              width: 300,
              title: "Fail",
              text: res?.message ?? "Something wrong, Try again!",
            });
            return;
          }
        })();
      }
    });
  };

  const rejectHandler = async (id) => {
    const token = decodeData(localStorage.getItem("y_g_n_d_a"));
    if (!token) return;
    SweetAlert.fire({
      title: "Remark!",
      icon: "warning",
      width: 300,
      input: "text",
      inputAttributes: {
        autocapitalize: "off",
        required: true,
      },
      inputValidator: (value) => {
        if (!value) {
          return "Remark field is required!";
        }
      },
      showCancelButton: true,
      confirmButtonText: "Approve",
      confirmButtonColor: "#3085d6",
      showLoaderOnConfirm: true,
    }).then((result) => {
      if (result.isConfirmed) {
        (async () => {
          setLoading(true);
          const res = await postMethod(
            `${WITHDRAW_REJECT_API(id)}?updated_at=${currentDate()}`,
            { remark: result.value },
            token.token
          );
          res && setLoading(false);
          if (res.status === "error") {
            SweetAlert.fire({
              icon: "error",
              width: 300,
              title: "Fail",
              text: res.message,
            });
          }
          if (res.status === "success") {
            SweetAlert.fire({
              title: "Approved!",
              text: "You has been saved.",
              icon: "success",
              width: 300,
            });
            setConfirm(!confirm);
            dispatch(statusHandler(!value));
            return;
          } else {
            SweetAlert.fire({
              icon: "error",
              width: 300,
              title: "Fail",
              text: res?.message ?? "Something wrong, Try again!",
            });
            return;
          }
        })();
      }
    });
  };

  const searchHandler = (e) => {
    setSearchName(e.target.value);
  };
  const searchSubmitHandler = () => {
    setRefr((prev) => !prev);
  };
  const filterHandler = async (e) => {
    setStatus(e.target.value);
  };
  const searchIdHandler = (e) => {
    setSearchId(e.target.value);
  };

  useEffect(() => {
    let total = 0;
    data.length > 0 &&
      data.map((el) => {
        if (el.status === "Approved") {
          total += el.amount;
        }
      });
    setWithdrawTotal(total);
  }, [data, confirm]);

  const chunk = (num) => {
    let str = num.toString().split(".");
    if (str[0].length >= 4) {
      str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, "$1,");
    }
    if (str[1] && str[1].length >= 5) {
      str[1] = str[1].replace(/(\d{3})/g, "$1 ");
    }
    return str.join(".");
  };
  return (
    <>
      <Layout>
        <Breadcrumbs title="Withdraw" />
        <Container fluid={true}>
        {loading && <Loader />}
          <Row>
            <Col sm="12">
              <Card>
                <CardHeader className="py-4">
                  <Row className="justify-content-end">
                    <Col md="4 px-1 mb-2">
                      <Input
                        className="form-control"
                        onChange={searchIdHandler}
                        type="number"
                        placeholder="Search ID"
                      />
                    </Col>
                    <Col md="4 px-1 mb-2">
                      <Input
                        className="form-control"
                        onChange={searchHandler}
                        type="text"
                        placeholder="Search Name"
                      />
                    </Col>
                    <Col md="4 px-1 mb-2">
                      <Input
                        type="select"
                        name="select"
                        className="form-control"
                        onChange={filterHandler}
                      >
                        <option value="">Select Action</option>
                        <option value="Pending">Pending</option>
                        <option value="Approved">Approved</option>
                        <option value="Rejected">Rejected</option>
                      </Input>
                    </Col>
                    <Col md="4 px-1 mb-2">
                      <DateRangeFormat
                        setStartDate={setStartDate}
                        setEndDate={setEndDate}
                      />
                    </Col>
                    <Col md="4 px-1 mb-2">
                      <button
                        className="w-100 btn text-white btn-primary"
                        disabled={loading}
                        type="button"
                        onClick={searchSubmitHandler}
                      >
                        {loading ? "Loading..." : "Submit"}
                      </button>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody className="position-relative">
                  <TableScroll>
                    <Table className="table-border-vertical">
                      <thead>
                        <tr
                          className="bg-dark"
                          style={{ verticalAlign: "middle" }}
                        >
                          <th className="text-center text-white" scope="col">
                            <b>{"No."}</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>{"User ID"}</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>{"Name"}</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>{"Bank"}</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>{"Amount"}</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>{"Action"}</b>
                          </th>
                          {role !== "streamer" && (
                            <th className="text-center text-white" scope="col">
                              <b>{"Reg Phone"}</b>
                            </th>
                          )}
                          {role !== "streamer" && (
                            <th className="text-center text-white" scope="col">
                              <b>{"Bank Acc Name"}</b>
                            </th>
                          )}
                          {role !== "streamer" && (
                            <th className="text-center text-white" scope="col">
                              <b>{"Bank Acc No."}</b>
                            </th>
                          )} 
                          <th className="text-center text-white" scope="col">
                            <b>{"Before"}</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>{"After"}</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>{"Player Time"}</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>{"Cashier Time"}</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>{"Operator"}</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>{"Remark"}</b>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {data?.length > 0 &&
                          data?.map((d, i) => (
                            <tr key={i} style={{ verticalAlign: "middle" }}>
                              <th className="text-center" scope="row">{`${
                                i + from
                              }.`}</th>
                              <td className="text-center">{d.user_id}</td>
                              <td className="text-center">{d.user_name}</td>
                              <td className="text-center">
                                {d.payment_name}
                              </td>
                              <td className="text-end">{chunk(d.amount)}</td>
                              <td className="text-center">
                                {d.status === "Pending" ? (
                                  role === "streamer" ? (
                                    <span style={{ color: "orange" }}>
                                      {d.status}
                                    </span>
                                  ) : (
                                    <div>
                                      <span
                                        className="btn btn-success btn-sm px-2 py-1"
                                        onClick={() => approveHandler(d.id)}
                                        style={{ marginRight: "3px" }}
                                      >
                                        Accept
                                      </span>
                                      <span
                                        className="btn btn-danger btn-sm px-2 py-1"
                                        onClick={() => rejectHandler(d.id)}
                                        style={{ marginRight: "3px" }}
                                      >
                                        Decline
                                      </span>
                                    </div>
                                  )
                                ) : (
                                  <span
                                    style={{
                                      color: `${
                                        d.status === "Approved"
                                          ? "green"
                                          : "red"
                                      }`,
                                    }}
                                  >
                                    {d.status}
                                  </span>
                                )}
                              </td>
                              {role !== "streamer" && (
                                <td className="text-center">{d.user_phone}</td>
                              )}
                              {role !== "streamer" && (
                                <td className="text-center">
                                  {d.name}
                                </td>
                              )}
                              {role !== "streamer" && (
                                <td
                                  className="text-center text-danger"
                                  style={{ fontWeight: "bolder" }}
                                >
                                  {d.phone}
                                </td>
                              )}
                              <td className="text-center">
                                {chunk(
                                  d.initial_balance ? d.initial_balance : 0
                                )}
                              </td>
                              <td className="text-center">
                                {chunk(d.after_balance ? d.after_balance : 0)}
                              </td>
                              <td className="text-center">{d.created_at}</td>
                              <td className="text-center">{d.updated_at}</td>
                              <td className="text-center">
                                {d.receive_name ? d.receive_name : "--"}
                              </td>
                              <td className="text-center">
                                {d.remark ? d.remark : "--"}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                      <tfoot>
                        <tr className="bg-dark">
                          <td
                            colSpan={role === "streamer" ? 3 : 4}
                            className="text-center text-white"
                          >
                            Total
                          </td>
                          <td className="text-end text-white">
                            {withdrawTotal.toLocaleString('en-us')}
                          </td>
                          <td colSpan={10} className="text-end text-white"></td>
                        </tr>
                      </tfoot>
                    </Table>
                  </TableScroll>
                </CardBody>
                <CardFooter className="d-flex justify-content-end">
                  <Pagination
                    setPage={setPage}
                    totalPage={totalPage}
                    setLoading={setLoading}
                  />
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </Container>
      </Layout>
    </>
  );
};

export default Withdraw;
