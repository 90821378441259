import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
} from "reactstrap";
import { deleteMethod, getMethod, postMethodFormData } from "../api";
import Breadcrumbs from "../layout/breadcrumb";
import Layout from "./Layout";
import SweetAlert from "sweetalert2";
import Loader from "../layout/loader/Loader";
import TableScroll from "../utils/TableScroll";
import { decodeData, numericeValueFilter } from "../utils/Helper";
import { PROVIDER_TYPE_API, PROVIDER_TYPE_DELETE_API, PROVIDER_TYPE_UPDATE_API } from "../api/routes";
import ModalImage from "react-modal-image";

const GameTypes = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [refr, setRefr] = useState(true);
  const [editPayment, setEditPayment] = useState(false);
  const [createPayment, setCreatePayment] = useState(false);
  const [typeName, setTypeName] = useState("");
  const [typeImage, setTypeImage] = useState("");
  const [typeNameValidate, setTypeNameValidate] = useState("");
  const [typeImageValidate, setTypeImageValidate] = useState("");
  const [gameTypeid, setGameTypeid] = useState("");
  const [status, setStatus] = useState("");
  const [sort, setSort] = useState('');

  useEffect(() => {
    (async () => {
      setLoading(true);
      const res = await getMethod(PROVIDER_TYPE_API + '?dashboard=true&sortColumn=sort&sortDirection=asc');
      res && setLoading(false);
      res?.status === "success" && setData(res?.data);
    })();
  }, [refr]);

  const editOpenModal = (data = {}) => {
    setEditPayment(!editPayment);
    setTypeName(data?.name ?? "");
    setStatus(data?.status ?? "");
    setSort(data?.sort || '');
    setTypeImage("");
    setTypeNameValidate("");
    setTypeImageValidate("");
  };

  const createOpenModal = () => {
    setCreatePayment(!createPayment);
    setTypeName("");
    setTypeImage("");
    setTypeNameValidate("");
    setTypeImageValidate("");
    setStatus("");
  };

  const statusHandler = (e) => {
    setStatus(e.target.value);
  };

  const createSubmitHandler = async (e) => {
    e.preventDefault();
    if (!typeImage) {
      setTypeNameValidate("Type name is required.");
      return;
    }
    if (!typeImage) {
      setTypeImageValidate("Type image is required.");
      return;
    }
    const formData = new FormData();
    formData.append("name", typeName);
    formData.append("photo", typeImage);
    const token = decodeData(localStorage.getItem("y_g_n_d_a"));
    if (!token) return;
    setLoading(true);
    const res = await postMethodFormData(
      PROVIDER_TYPE_API,
      formData,
      token.token
    );
    res && setLoading(false);
    if (res?.status === "success") {
      setCreatePayment(false);
      setRefr(!refr);
      setTypeName("");
      setTypeImage("");
    } else {
      SweetAlert.fire({
        icon: "error",
        width: 300,
        title: "Sorry!",
        text:
          (res?.field ?? "") +
          " " +
          (res?.message ?? "Something wrong, Try again!"),
      });
      return;
    }
  };

  const editSubmitHandler = async () => {
    if (!gameTypeid) return;
    const formData = new FormData();
    formData.append("name", typeName);
    formData.append("photo", typeImage);
    formData.append("status", status);
    formData.append("sort", sort);
    const token = decodeData(localStorage.getItem("y_g_n_d_a"));
    if (!token) return;
    setLoading(true);
    const res = await postMethodFormData(
      PROVIDER_TYPE_UPDATE_API(gameTypeid),
      formData,
      token.token,
      'PUT'
    );
    res && setLoading(false);
    if (res?.status === "success") {
      setEditPayment(false);
      setRefr(!refr);
      setTypeName("");
      setTypeImage("");
      setStatus("");
      return;
    } else {
      SweetAlert.fire({
        icon: "error",
        width: 300,
        title: "Sorry!",
        text:
          (res?.field ?? "") +
          " " +
          (res?.message ?? "Something wrong, Try again!"),
      });
      return;
    }
  };

  const deleteHandler = async (id) => {
    const token = decodeData(localStorage.getItem("y_g_n_d_a"));
    if (!token) return;
    SweetAlert.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      width: 300,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true);
        (async () => {
          const res = await deleteMethod(
            PROVIDER_TYPE_DELETE_API(id),
            token.token
          );
          res && setLoading(false);
          if (res?.status === "success") {
            SweetAlert.fire({
              title: "Success!",
              text: "You has been deleted.",
              icon: "success",
              width: 300,
            });
            setRefr(!refr);
            return;
          } else {
            SweetAlert.fire({
              title: "Sorry!",
              text: res?.message ?? "Something wrong, Try again!",
              icon: "error",
              width: 300,
            });
            return;
          }
        })();
      }
    });
  };

  return (
    <>
      <Layout>
        <Breadcrumbs title="Game Types" />
        <Container fluid={true}>
        {loading && <Loader />}
          <Row>
            <Col sm="12">
              <Card>
                <CardHeader className="py-4 d-flex justify-content-end">
                  <Button
                    color=""
                    onClick={createOpenModal}
                    className="text-white"
                    style={{ backgroundColor: "#001753" }}
                  >
                    Create
                  </Button>
                </CardHeader>
                <CardBody className="position-relative">
                  <TableScroll>
                    <Table className="table-border-vertical">
                      <thead>
                        <tr className="bg-dark">
                          <th className="text-center text-white" scope="col">
                            <b>No.</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>Name</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>Photo</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>Status</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>Sort</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>Action</b>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.length > 0 &&
                          data.map((el, i) => (
                            <tr key={i} className="align-middle">
                              <th className="text-center" scope="row">{`${
                                i + 1
                              }.`}</th>
                              <th className="text-center">{el.name}</th>
                              <td className="text-center">
                                 <div
                                  style={{
                                    width: "50px",
                                    borderRadius: "5px",
                                    overflow: "hidden",
                                    margin: "auto",
                                  }}
                                >
                                  <ModalImage
                                    alt={el.name}
                                    small={el.photo}
                                    large={el.photo}
                                  />
                                </div>
                              </td>
                              <td className="text-center">
                                {el.status === "active" ? (
                                  <i className="fa fa-circle font-success f-12" />
                                ) : (
                                  <i className="fa fa-circle font-danger f-12" />
                                )}
                              </td>
                              <th className="text-center">{el.sort}</th>
                              <td className="text-center">
                                <div>
                                  <span
                                    onClick={() => {
                                      editOpenModal(el);
                                      setGameTypeid(el.id);
                                    }}
                                  >
                                    <i
                                      className="fa fa-pencil font-success"
                                      style={{
                                        width: 35,
                                        fontSize: 18,
                                        padding: 11,
                                        cursor: "pointer",
                                      }}
                                    ></i>
                                  </span>
                                </div>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  </TableScroll>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </Layout>
      {
        <Modal isOpen={createPayment} toggle={createOpenModal} centered>
          <Form onSubmit={createSubmitHandler}>
            <ModalHeader toggle={createOpenModal}>Create Game Type</ModalHeader>
            <ModalBody>
              <FormGroup>
                <Label htmlFor="">Type Name</Label>
                <Input
                  className="form-control"
                  type="text"
                  placeholder="Type Name"
                  onChange={(e) => {
                    setTypeName(e.target.value);
                    setTypeNameValidate("");
                  }}
                />
                {typeNameValidate && (
                  <div className="invalid-feedback d-block">
                    {typeNameValidate}
                  </div>
                )}
              </FormGroup>
              <FormGroup>
                <Label htmlFor="">Type Photo</Label>
                <Input
                  className="form-control"
                  type="file"
                  placeholder=""
                  onChange={(e) => {
                    setTypeImage(e.target.files[0]);
                    setTypeImageValidate("");
                  }}
                />
                {typeImageValidate && (
                  <div className="invalid-feedback d-block">
                    {typeImageValidate}
                  </div>
                )}
              </FormGroup>
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={createOpenModal}>
                Close
              </Button>
              <Button color="primary" type="submit">
                Save
              </Button>
            </ModalFooter>
          </Form>
        </Modal>
      }
      {
        <Modal isOpen={editPayment} toggle={editOpenModal} centered>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              editSubmitHandler();
            }}
          >
            <ModalHeader toggle={editOpenModal}>
              Edit Game Type
            </ModalHeader>
            <ModalBody>
              <FormGroup>
                <Label htmlFor="">Type Name</Label>
                <Input
                  className="form-control"
                  defaultValue={typeName}
                  type="text"
                  placeholder="Name"
                  onChange={(e) => {
                    setTypeName(e.target.value);
                    setTypeNameValidate("");
                  }}
                />
                {typeNameValidate && (
                  <div className="invalid-feedback d-block">
                    {typeNameValidate}
                  </div>
                )}
              </FormGroup>
              <FormGroup>
                <Label htmlFor="">Sort</Label>
                <Input
                  className="form-control"
                  defaultValue={sort}
                  type="text"
                  placeholder="Sort"
                  onChange={(e) => {
                    setSort(numericeValueFilter(e.target.value));
                  }}
                />
              </FormGroup>
              <FormGroup>
                <Label htmlFor="">Type Photo</Label>
                <Input
                  className="form-control"
                  type="file"
                  placeholder=""
                  onChange={(e) => {
                    setTypeImage(e.target.files[0]);
                    setTypeImageValidate("");
                  }}
                />
              </FormGroup>
              <Row>
                <Col>
                  <div className="mb-0">
                    <Label>Type Status</Label>
                    <Input
                      type="select"
                      name="select"
                      className="form-control form-control-sm digits"
                      onChange={statusHandler}
                      defaultValue={status}
                    >
                      <option value="">Choose</option>
                      <option value="active">Active</option>
                      <option value="disable">Disabled</option>
                    </Input>
                  </div>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={editOpenModal}>
                Close
              </Button>
              <Button color="primary" type="submit">
                Save
              </Button>
            </ModalFooter>
          </Form>
        </Modal>
      }
    </>
  );
};

export default GameTypes;
